<template>
  <v-main class="pa-2">
    <v-row>
      <v-col
        cols="12"
        xl="12"
        lg="12"
        md="12"
        sm="12"
        xs="12"
        class="colunaSaldo"
      >
        <saldo-inativo ref="saldos"></saldo-inativo>
      </v-col>
    </v-row>
    <v-row gutters="1" class="pa-0">
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
        <v-card class="pt-2 pl-5" max-width="100%" elevation="2">
          <v-row class="mt-0">
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="3">
              <v-autocomplete
                :items="listaContas"
                v-model="lancamento.id_conta_debito"
                name="conta_debito"
                label="Conta Débito"
                item-value="id_conta"
                item-text="conta"
                ref="conta_debito"
                value
                filled
                clearable
                @change="
                  buscaContasCredito();
                  mudaCampo('conta_credito');
                "
                @keyup.enter="mudaCampo('conta_credito')"
                @click:clear="listaContasCredito = []"
                hide-details
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="3">
              <v-autocomplete
                :items="listaContasCredito"
                v-model="lancamento.id_conta_credito"
                name="conta_credito"
                label="Conta Crédito"
                item-value="id_conta"
                item-text="conta"
                value
                ref="conta_credito"
                filled
                @change="mudaCampo('valor')"
                @keyup.enter="mudaCampo('valor')"
                :name="`conta_${Math.random()}`"
                clearable
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="3" sm="3" xs="3">
              <v-text-field
                v-model="lancamento.vlr_valor"
                name="valor"
                label="Valor"
                value
                filled
                ref="valor"
                @keyup.enter="mudaCampo('data_lancamento')"
                prepend-inner-icon="attach_money"
                v-mask-decimal.br="2"
                hide-details
                :name="`valor_${Math.random()}`"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="3" sm="4" xs="3">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateIniFormatted"
                    label="Lançamento"
                    prepend-inner-icon="event"
                    ref="data_lancamento"
                    filled
                    readonly
                    @blur="
                      lancamento.data_lancamento = parseDate(dateIniFormatted)
                    "
                    v-on="on"
                    hide-details
                  ></v-text-field>
                </template>

                <v-date-picker
                  locale="pt-br"
                  format="dd/MM/yyyy"
                  v-model="lancamento.data_lancamento"
                  no-title
                  @input="menu1 = false"
                  ref="data_lancamento"
                  hide-details
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" xl="1" lg="1" md="2" sm="6" xs="12">
              <v-btn
                ref="transferir"
                block
                dark
                @click="transferir()"
                color="bt_transferir"
              >
                Transferir
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
              <v-progress-linear
                v-show="barTabela"
                color="red lighten-2"
                buffer-value="0"
                stream
              ></v-progress-linear>
              <v-data-table
                :headers="headers"
                :items="listaTransfer"
                :loading="loading_table"
                loading-text="Carregando... Aguarde"
                dense
                :search="search"
                no-data-text="Nenhum resultado encontrado!"
                no-results-text="Nenhum resultado encontrado!"
                class="elevation-1"
              >
                <template v-slot:item.vlr_valor="{ item }">
                  <span>{{ formatar_valor(item.vlr_valor) }}</span>
                </template>
                <template v-slot:item.data_lancamento="{ item }">
                  <span>{{ formatDate(item.data_lancamento) }}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      :timeout="2000"
      :color="corMsg"
      :multi-line="'multi-line'"
      :vertical="'vertical'"
      :top="true"
      v-model="mostraMsg"
    >
      {{ textSnack }}
    </v-snackbar>
  </v-main>
</template>
<script>
var moment = require("moment");

import { formata } from "../plugins/format";
import SaldoInativo from "./SaldoInativo.vue";
import { VDatePicker } from "vuetify/lib";
const auth = {
  headers: {
    Authorization: "bearer " + window.sessionStorage.getItem("token"),
  },
};
export default {
  name: "TabTransferencia",
  mixins: [formata],
  components: { SaldoInativo },
  props: {
    source: String,
  },
  mounted() {
    this.buscaContas();

    this.lista();
    this.limpaCampos();
    setTimeout(() => {
      this.buscaSaldos();
    }, 300);
  },
  data: () => ({
    lancamento: {
      id_conta_credito: "",
      id_conta_debito: "",
      id_fin_grupo_departamento: "",
      vlr_valor: "",
      data_lancamento: moment().format("YYYY-MM-DD"),
      data_vencimento: "",
    },
    selDel: "",
    dialogDelete: false,
    barSaldos: true,
    barTabela: false,
    isLoading: false,
    listaContas: [],
    // date_ini: moment().format("YYYY-MM-01"),
    //date_vencimento: new Date().toISOString().substr(0, 10),
    //date_pagamento: new Date().toISOString().substr(0, 10),
    datePagamentoFormatted: "",
    dateVencimentoFormatted: "",
    //    dateIniFormatted: "", //vm.formatDate(new Date().toISOString().substr(0, 10)),

    menu1: false,
    menu2: false,
    menu3: false,
    loading_table: false,
    headers: [],
    search: "",
    loading_filtrar: false,
    loading_baixar: false,
    grupos: [],
    lancpai: [],
    historicos: [],
    fornecedores: [],
    listaTransfer: [],
    listaContasDebito: [],
    listaContasCredito: [],
    saldos: [],
    mostraMsg: false,
    cor: "",
    textSnack: "",
    corMsg: "",

    dialogPagar: false,
    selPagar: "",
    headers: [
      {
        text: "Nº",
        sortable: true,
        align: "left",
        value: "id_lancamento",
      },
      {
        text: "Descrição",
        value: "descricao",
      },
      {
        text: "Identif.",
        value: "identif",
        align: "left",
      },
      {
        text: "Data",
        value: "data_lancamento",
        align: "right",
      },
      {
        text: "Valor",
        value: "vlr_valor",
        align: "right",
      },

      {
        text: "Tipo",
        value: "tipo",
        align: "left",
      },

      {
        text: "Banco/Conta",
        value: "conta",
        align: "left",
      },
      {
        text: "",
        value: "acao",
        align: "left",
      },
    ],
  }),
  computed: {
    dateIniFormatted() {
      return this.formatDate(this.lancamento.data_lancamento);
    },

    /*dateVencimentoFormatted() {
      return this.formatDate(this.lancamento.data_vencimento);
    },*/
  },

  watch: {
    "lancamento.data_lancamento"(val) {
      this.dateIniFormatted = this.formatDate(this.lancamento.data_lancamento);
      //this.lancamento.dt_lancamento = this.dateIniFormatted;
    },
    "lancamento.id_conta_debito"(val) {
      //alert(val);
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    mudaTab(tab) {
      this.$emit("mudaTab", tab);
    },
    mudaCampo(campo) {
      eval("this.$refs." + campo + ".focus()");
    },
    validacao() {
      /* if (this.lancamento.documento == "") {
        this.textSnack = "Preencha o campo Documento!";
        this.mostraMsg = true;
        this.corMsg = "error";
        this.mudaCampo("documento");
        this.$refs.documento.focus();
        return false;
      }*/
      if (
        this.lancamento.id_conta_credito == "" ||
        this.lancamento.id_conta_credito == null
      ) {
        this.textSnack = "Selecione uma conta Crédito!";
        this.mostraMsg = true;
        this.corMsg = "error";
        this.$refs.conta_credito.focus();
        return false;
      }
      if (
        this.lancamento.id_conta_debito == "" ||
        this.lancamento.id_conta_debito == null
      ) {
        this.textSnack = "Selecione uma conta Débito!";
        this.mostraMsg = true;
        this.corMsg = "error";
        this.$refs.conta_debito.focus();
        return false;
      }
      if (this.lancamento.vlr_valor == "") {
        this.textSnack = "Informe um Valor!";
        this.mostraMsg = true;
        this.corMsg = "error";
        this.$refs.valor.focus();
        return false;
      }

      return true;
    },
    transferir() {
      if (!this.validacao()) {
        return false;
      }
      axios
        .post(
          `/fin/lancamento/transfer`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            id_conta_debito: this.lancamento.id_conta_debito,
            vlr_valor: this.lancamento.vlr_valor,
            id_conta_credito: this.lancamento.id_conta_credito,
            data_lancamento: this.lancamento.data_lancamento,
          },
          auth
        )
        .then((response) => {
          console.log(response);

          this.buscaSaldos();
          this.lista();
          this.limpaCampos();
          //this.buscaContasPagar();
          //this.lancpai = response.data;
        })
        .catch((e) => {
          // this.limpaCampos();
          // this.loading = false;
          // console.log("mensagem=0" + e.response.data);
          //console.log(e.response.data);

          this.textSnack = e.response.data.msg;
          this.mostraMsg = true;
          this.corMsg = "error";
        });
    },
    limpaCampos() {
      this.lancamento.id_conta_debito = "";
      this.lancamento.id_conta_credito = "";
      this.lancamento.vlr_valor = "";
    },
    lista(itens) {
      //console.log(lancs);
      axios
        .post(
          `/fin/lancamento/listaTransferencia`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
          auth
        )
        .then((response) => {
          console.log(response);
          //this.buscaSaldos();
          //this.buscaContasReceber();
          this.listaTransfer = response.data;
        });
    },
    deleteItem(item) {
      //console.log(item);
      //console.log(item.id_lancamento);
      //this.editedIndex = this.lancamento.indexOf(item);
      //this.editedItem = Object.assign({}, item);
      this.selDel = item.id_lancamento;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.listaLancamentos.splice(this.editedIndex, 1);
      this.closeDelete();
      axios
        .post(
          `/fin/lancamento/remove`,
          { id: this.selDel, cnpj: window.sessionStorage.getItem("cnpj") },
          auth
        )
        .then((response) => {
          this.selDel = "";
          this.buscaContasReceber();
          console.log(response);
          //this.grupos = response.data;
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    editItem(item) {
      this.lancamento = item;
    },
    formata_moeda(val) {
      let valor = this.formatar_moeda(val);
      return valor;
    },
    addDescricao(val) {
      this.lancamento.descricao = val;
    },
    selecionarConta(id) {
      this.lancamento.id_conta = id;
      // sessionStorage.setItem("selconta", id);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getStatus(status) {
      if (status === "Cancelada") {
        return "cancelada";
      }
    },

    buscaGrupos() {
      axios
        .post(
          `/fin/grupo/listaOp`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            tipo: "C",
          },
          auth
        )
        .then((response) => {
          this.grupos = response.data;
        });
    },
    selConta(e) {
      //console.log(e);
      this.lancamento.id_conta = e;
    },
    openreceber() {
      alert("receber");
    },
    buscaSaldos() {
      this.$refs.saldos.buscaSaldos();
    },
    filtroBuscar() {
      setTimeout(() => this.buscaContasReceber(), 500);
    },
    buscaContasReceber() {
      /*if (
        this.lancamento.data_vencimento == "" ||
        this.lancamento.data_vencimento == null
      ) {
        return false;
      }*/
      /* if (
        this.lancamento.id_fin_grupo_departamento == "" ||
        this.lancamento.id_fin_grupo_departamento == null
      ) {
        return false;
      }*/
      this.barTabela = true;
      axios
        .post(
          `/fin/lancamento/contas_receber`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            data_vencimento: this.lancamento.data_vencimento,
            grupo: this.lancamento.id_fin_grupo_departamento,
          },
          auth
        )
        .then((response) => {
          this.listaContasReceber = response.data;
          this.barTabela = false;
        });
    },
    buscaTipoDoc() {
      axios
        .post(
          `/fin/tipo_doc/lista`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
          auth
        )
        .then((response) => {
          this.listaTipoDoc = response.data;
        });
    },
    buscaContas() {
      axios
        .post(
          `/fin/conta/lista`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
          auth
        )
        .then((response) => {
          this.listaContas = response.data;
        });
    },
    buscaContasCredito(val) {
      this.listaContasCredito = [];
      axios
        .post(
          `/fin/conta/lista`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            id_conta: this.lancamento.id_conta_debito,
          },
          auth
        )
        .then((response) => {
          this.listaContasCredito = response.data;
        });
    },
  },
};
</script>
<style scoped>
.ativa {
  border: 2px solid #000 !important;
}
</style>
