<template>
  <v-main class="ml-0 mt-2 mr-2">
    <div class="ml-2">
      <v-card max-width="100%" elevation="1">
        <v-card elevation="0" class="">
          <v-alert
            style="max-height:47px!important;padding:0px!important;margin-bottom:0px!important;"
            color="topo_menu"
            class="text-left"
          >
            <v-breadcrumbs :items="itemsBread">
              <template v-slot:divider>
                <v-icon>mdi-forward</v-icon>
              </template>
            </v-breadcrumbs>
          </v-alert>
        </v-card>
        <v-tabs v-model="tab" justify-center>
          <v-tabs-slider></v-tabs-slider>

          <v-tab
            style="color:#358447;font-weight:bolder"
            @click="
              openlanc();
              addBread('.');
            "
            href="#tab1"
          >
            <v-icon dark color="bt_lanc" left>
              mdi-newspaper-plus
            </v-icon>
            Lançamentos
          </v-tab>

          <v-tab
            color="bt_lanc"
            style="color:#B15555;font-weight:bolder"
            dark
            @click="
              openPagar();
              addBread('Contas a Pagar');
            "
            href="#tab2"
          >
            <v-icon color="bt_pagar" left> mdi-tray-minus </v-icon>
            Contas a Pagar
          </v-tab>

          <v-tab
            style="color:#1C4694;font-weight:bolder"
            @click="
              openReceber();
              addBread('Contas a Receber');
            "
            href="#tab3"
          >
            <v-icon color="bt_receber" left>
              mdi-tray-plus
            </v-icon>
            Contas a Receber
          </v-tab>
          <v-tab
            style="color:#E98B34;font-weight:bolder"
            dark
            color="secondary"
            @click="
              openTransfer();
              addBread('Transferências');
            "
            href="#tab4"
          >
            <v-icon color="bt_transferir" left>
              mdi-transfer
            </v-icon>
            Transferências
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            transition="fade-transition"
            reverse-transition="fade-transition"
            :key="'tab1'"
            :value="'tab1'"
          >
            <MenuLancamento ref="lancamento"></MenuLancamento>
          </v-tab-item>

          <v-tab-item
            transition="fade-transition"
            reverse-transition="fade-transition"
            :key="'tab2'"
            :value="'tab2'"
          >
            <TabContasPagar @mudaTab="changeTab" ref="cPagar"></TabContasPagar>
          </v-tab-item>

          <v-tab-item
            transition="fade-transition"
            reverse-transition="fade-transition"
            :key="'tab3'"
            :value="'tab3'"
          >
            <TabContasReceber
              @mudaTab="changeTab"
              ref="cReceber"
            ></TabContasReceber>
          </v-tab-item>
          <v-tab-item
            transition="fade-transition"
            reverse-transition="fade-transition"
            :key="'tab4'"
            :value="'tab4'"
          >
            <TabTransferencia
              @mudaTab="changeTab"
              ref="cTransferencia"
            ></TabTransferencia>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </v-main>
</template>

<script>
import MenuLancamento from "@/views/MenuLancamento";
import TabContasPagar from "@/views/TabContasPagar";
import TabContasReceber from "@/views/TabContasReceber";
import TabTransferencia from "@/views/TabTransferencia";

var moment = require("moment");

const auth = {
  headers: {
    Authorization: "bearer " + window.sessionStorage.getItem("token"),
  },
};
export default {
  components: {
    MenuLancamento,
    TabContasPagar,
    TabContasReceber,
    TabTransferencia,
  },

  props: {
    source: String,
  },
  mounted() {
    //this.buscaNotas();
  },
  data: (vm) => ({
    itemsBread: [
      {
        text: "Financeiro",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Lançamentos",
        disabled: false,
        href: "/dashboard/lancamentos",
      },
      {
        text: ".",
        disabled: true,
        href: "lancamentos",
      },
    ],
    tabs: null,
    tab: null,
    money: {
      decimal: ".",
      thousands: " ",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: true /* doesn't work with directive */,
    },
    //date_ini: moment().format("YYYY-MM-01"),
    // date_fim: new Date().toISOString().substr(0, 10),
    //dateFimFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    //dateIniFormatted: moment().format("01/MM/YYYY"),
    // menu1: false,
    //menu2: false,
    // loading_table: false,
    // headers: [],
    search: "",
    // loading_filtrar: false,
    // loading_baixar: false,
    items: [],
    headers: [
      {
        text: "Chave",
        sortable: true,
        align: "left",
        value: "chave",
      },
      {
        text: "Data",
        value: "data",
      },
      {
        text: "Número",
        value: "nf_numero",
        align: "right",
      },
      {
        text: "Valor",
        value: "valor",
        align: "right",
      },
      {
        text: "Base",
        value: "base",
        align: "right",
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
      },
    ],
  }),
  computed: {
    computedDateInFormatted() {
      return this.formatDate(this.date_ini);
    },
    computedDateFimFormatted() {
      return this.formatDate(this.date_fim);
    },
  },

  watch: {
    date_ini(val) {
      this.dateIniFormatted = this.formatDate(this.date_ini);
    },
    date_fim(val) {
      this.dateFimFormatted = this.formatDate(this.date_fim);
    },
  },
  methods: {
    changeTab(val, e) {
      this.tab = val;
      this.$refs.lancamento.buscaSaldos();
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getStatus(status) {
      if (status === "Cancelada") {
        return "cancelada";
      }
    },
    openlanc() {
      this.$refs.lancamento.zeraLancamentos();
      this.$refs.lancamento.buscaSaldos();
      this.$refs.lancamento.buscaLancamentos();
    },
    openReceber() {
      setTimeout(() => this.$refs.cReceber.buscaSaldos(), 500);
      setTimeout(() => this.$refs.cReceber.buscaContasReceber(), 500);
      setTimeout(() => this.$refs.cReceber.zeraCampos(), 300);
      // this.$refs.cReceber.openreceber();
      //this.$refs.receber.buscaSaldos();
      // this.$refs.cReceber.buscaGrupos();
    },
    openPagar() {
      setTimeout(() => this.$refs.cPagar.buscaSaldos(), 500);
      setTimeout(() => this.$refs.cPagar.buscaContasPagar(), 500);
      setTimeout(() => this.$refs.cPagar.zeraCampos(), 300);

      // this.$refs.saldos.buscaSaldos();
      //this.$refs.pagar.buscaGrupos();
    },
    openTransfer() {
      setTimeout(() => this.$refs.cTransferencia.buscaSaldos(), 500);
      setTimeout(() => this.$refs.cTransferencia.buscaContas(), 500);
      setTimeout(() => this.$refs.cTransferencia.lista(), 400);
    },
    addBread(menu) {
      this.itemsBread.pop();
      this.itemsBread.push({
        text: menu,
        disabled: true,
        href: "lancamentos",
      });
    },
  },
};
</script>
<style>
.cancelada {
  color: red;
}
.colunaSaldo {
  padding: 0px;
}

.v-application .pa-4 {
  padding: 5px !important;
}
.v-alert {
  padding: 0px;
}

.v-tabs-bar__content {
  height: 32px !important;
}
.v-tabs-bar {
  height: 32px !important;
}
.v-tab.v-tab {
  color: inherit;
  border: 1px solid #ccc;
  margin-left: 5px;
}
</style>
