<template>
  <div
    style="width:100%;padding-top: 0px;
    padding-bottom: 0px; margin:0px"
  >
    <v-card class="pa-1" max-width="100%" elevation="0">
      <v-progress-linear
        v-show="barSaldos"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-sheet class="mx-auto" elevation="0" max-width="100%">
        <v-slide-group
          v-model="selectedItem"
          class="pa-2"
          mandatory
          show-arrows
        >
          <v-slide-item
            v-for="(item, i) in saldos"
            :key="i"
            v-slot="{ active, toggle }"
            class="item.cor"
            dark
          >
            <v-card
              class="ma-1"
              style="padding-top:2px!important"
              :class="active ? 'desativado' : 'desativado'"
              height="80"
              width="170px"
            >
              <v-card-text
                ><div class="pa-0">
                  <p style="color:#3E5286;font-size:0.85em">
                    {{ item.nome_banco }}
                  </p>
                  <p style="color:#3E5286;font-size:0.80rem;line-height:10px">
                    {{ item.conta }}
                  </p>
                  <p :class="item.class">
                    {{ formatar_valor(item.saldo) }}
                  </p>
                  <h6
                    style="color:#3E5286;font-size:0.85em;line-height:15px"
                    v-show="item.saldo_pend != 0"
                  >
                    pendente: {{ formatar_valor(item.saldo_pend) }}
                  </h6>
                </div></v-card-text
              >

              <v-row gutters="1" class="fill-height">
                <v-scale-transition>
                  <!--<v-icon
                    v-if="active"
                    color="green"
                    size="25"
                    style="margin-top:-75px;margin-left:80%"
                    v-text="'mdi-cash-check'"
                  ></v-icon>-->
                </v-scale-transition>
              </v-row>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </v-card>
  </div>
</template>

<script>
import { formata } from "../plugins/format";

const auth = {
  headers: {
    Authorization: "bearer " + window.sessionStorage.getItem("token"),
  },
};
export default {
  name: "saldoInativo",
  props: {
    source: String,
  },
  mixins: [formata],
  mounted() {
    this.buscaSaldos();
    sessionStorage.setItem("selconta", "3");
  },
  data: (vm) => ({
    barSaldos: true,
    saldos: [],
    selectedItem: 0,
  }),
  computed: {},

  watch: {
    selectedItem(val) {
      //alert();
      //this.$emit("selecionaConta", this.saldos[val].id_conta);
      //   this.$emit("changeTab", "tab1");
      ///sessionStorage.setItem("selconta", this.saldos[val].id_conta);
      //sessionStorage.setItem("selindex", val);
      //sessionStorage.setItem("conta", this.saldos[val].nome_banco);
    },
  },
  methods: {
    formatar_valor_check(val) {
      //  console.log(val);
      if (val > 0) {
        this.pos = true;
        this.neg = false;
      } else {
        this.pos = false;
        this.neg = true;
      }
    },
    selecionarConta(conta, index) {
      console.log("conta=" + conta);
      console.log("index=" + index);
      //this.$emit("changeTab", "tab1");
      //console.log("index " + index);
      // this.$emit("selecionaConta", id);
      //  setTimeout(() => (this.selectedItem = parseInt(index)), 5);
      //  sessionStorage.setItem("selconta", id);
      //  sessionStorage.setItem("selindex", index);
    },
    buscaSaldos() {
      axios
        .post(
          `/fin/conta/saldos`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
          auth
        )
        .then((response) => {
          this.saldos = response.data.lista;

          //this.selecionarConta(3, 1);

          this.barSaldos = false;
        });
    },
  },
};
</script>
<style scoped>
.cancelada {
  color: red;
}
.ativa {
}
.negativo {
  color: #d90000;
  font-weight: 700;
  font-size: 1.5em;
}
.desativado {
  /*border: 2px solid #000 !important;*/
  border-right: 2px solid #c5c3c3;
}
.positivo {
  color: #187052;
  font-weight: 700;
  font-size: 1.5em;
}
</style>
