<template>
  <v-container>
    <v-row gutters="1" class="pa-0">
      <v-col>
        <v-card class="mt-0">
          <v-card-title>
            Lançamentos Extras
            <v-spacer></v-spacer>
            <button @click="fechar()">&times;</button>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="listaLancamentos"
            :loading="loading_table"
            loading-text="Carregando... Aguarde"
            dense
            :search="search"
            no-data-text="Nenhum resultado encontrado!"
            no-results-text="Nenhum resultado encontrado!"
            class="elevation-1"
          >
            <!--<template v-slot:item.actions="{ item }">
            <v-icon color="primary" small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="error" small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>--->
            <template v-slot:item.vlr_valor="{ item }">
              <span>{{ formatar_valor(item.vlr_valor) }}</span>
            </template>
            <template v-slot:item.data_lancamento="{ item }">
              <span>{{ formatDate(item.data_lancamento) }}</span>
            </template>
            <template v-slot:item.data_vencimento="{ item }">
              <span>{{ formatDate(item.data_vencimento) }}</span>
            </template>
            <template v-slot:item.pai="{ item }">
              <div v-if="item.pai">
                <v-btn icon color="error" @click="openFilhos()" fab x-small>
                  <v-icon>mdi-link-variant</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
var moment = require("moment");

import { formata } from "../plugins/format";

const auth = {
  headers: {
    Authorization: "bearer " + window.sessionStorage.getItem("token"),
  },
};
export default {
  name: "DialogItensLancamento",
  //components: { Mensagem },
  mixins: [formata],
  props: ["id_pai"],

  mounted() {
    // this.buscaNotas();
    //this.id_pai = 0;
    // this.buscaLancamentosFilhos();
  },

  data: (vm) => ({
    isLoading: false,
    loading_table: false,
    search: "",
    listaLancamentos: [],
    // id_pai: 0,
    headers: [
      {
        text: "Nº",
        sortable: true,
        align: "left",
        value: "id_lancamento",
      },
      {
        text: "Descrição",
        value: "descricao",
      },
      {
        text: "Identif.",
        value: "identif",
        align: "left",
      },
      {
        text: "Data",
        value: "data_lancamento",
        align: "right",
      },
      {
        text: "Valor",
        value: "vlr_valor",
        align: "right",
      },

      {
        text: "Vencimento",
        value: "data_vencimento",
        align: "right",
      },
      {
        text: "Tipo",
        value: "tipo",
        align: "left",
      },
      {
        text: "Documento",
        value: "documento",
        align: "left",
      },
      {
        text: "Forma Pagto",
        value: "conta",
        align: "left",
      },
      /*{
        text: "Extras",
        value: "pai",
        align: "left",
      },
      //{ text: "Ações", value: "actions", sortable: false },*/
    ],
  }),
  computed: {},

  watch: {
    //id_pai(val) {
    // alert("valor", val);
    //if (val != "") {
    //this.buscaLancamentosFilhos(val);
    //}
    //},
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    fechar() {
      this.$emit("fechouDialog");
    },
    deleteItem(item) {
      console.log(item);
      console.log(item.id_lancamento);
      //this.editedIndex = this.lancamento.indexOf(item);
      //this.editedItem = Object.assign({}, item);
      this.selDel = item.id_lancamento;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.listaLancamentos.splice(this.editedIndex, 1);
      this.closeDelete();
      axios
        .post(
          `/fin/lancamento/remove`,
          { id: this.selDel, cnpj: window.sessionStorage.getItem("cnpj") },
          auth
        )
        .then((response) => {
          this.selDel = "";
          this.buscaLancamentos();
          //this.grupos = response.data;
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    formata_moeda(val) {
      let valor = this.formatar_moeda(val);
      return valor;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    buscaLancamentosFilhos(id_pai) {
      this.loading_table = true;
      axios
        .post(
          `/fin/lancamento/listaFilhos`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            id_pai: id_pai,
          },
          auth
        )
        .then((response) => {
          this.listaLancamentos = response.data;
          this.barTabela = false;
          this.loading_table = false;
        });
    },
  },
};
</script>
<style>
.v-list-item {
  min-height: 28px !important;
}
.v-list-item__title {
  font-size: 1.2em !important;
}
</style></style>
