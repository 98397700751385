<template>
  <v-main class="pa-2">
    <v-row>
      <v-col
        cols="12"
        xl="12"
        lg="12"
        md="12"
        sm="12"
        xs="12"
        class="colunaSaldo"
      >
        <saldo-inativo ref="saldos"></saldo-inativo>
      </v-col>
    </v-row>

    <v-row gutters="1" class="pa-0">
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
        <v-card class="pa-5" max-width="100%" elevation="2">
          <v-row class="mt-0">
            <v-radio-group
              filled
              @change="filtrarData()"
              v-model="tipo_data"
              row
            >
              <v-radio label="Lancamento" value="lancamento"></v-radio>
              <v-radio label="Vencimento" value="vencimento"></v-radio>
            </v-radio-group>
            <v-col cols="12" xl="2" lg="2" md="3" sm="4" xs="6">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                transition="scroll-y-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateInicialFormatted"
                    label="data inicial"
                    persistent-hint
                    prepend-inner-icon="event"
                    filled
                    clearable
                    v-on="on"
                    @input="
                      lancamento.data_final = parseDate(dateFinalFormatted);
                      filtroBuscar();
                    "
                    @click:clear="
                      lancamento.data_inicial = '';
                      listaContasReceber = [];
                    "
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  v-model="lancamento.data_inicial"
                  no-title
                  @input="
                    menu2 = false;

                    filtroBuscar();
                  "
                  hide-details
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="3" sm="3" xs="3">
              <v-menu
                v-model="menuFin"
                :close-on-content-click="false"
                transition="scroll-y-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateFinalFormatted"
                    label="data final"
                    persistent-hint
                    prepend-inner-icon="event"
                    filled
                    clearable
                    v-on="on"
                    @blur="
                      lancamento.data_final = parseDate(dateFinalFormatted);
                      filtroBuscar();
                    "
                    @click:clear="
                      lancamento.data_final = '';
                      listaContasReceber = [];
                    "
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  v-model="lancamento.data_final"
                  no-title
                  @input="menuFin = false"
                  hide-details
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" xl="2" lg="2" md="3" sm="2" xs="2">
              <v-menu
                ref="menu3"
                v-model="menu3"
                :close-on-content-click="false"
                transition="scroll-y-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="datePagamentoFormatted"
                    label="Data Pagamento"
                    persistent-hint
                    prepend-inner-icon="event"
                    filled
                    @blur="
                      lancamento.data_pagamento = parseDate(
                        datePagamentoFormatted
                      )
                    "
                    v-on="on"
                    hide-details
                    clearable
                    @click:clear="lancamento.data_pagamento = ''"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  v-model="lancamento.data_pagamento"
                  no-title
                  @input="menu3 = false"
                  hide-details
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" xl="2" lg="2" md="6" sm="6" xs="6">
              <v-autocomplete
                transition="fab-transition"
                :items="listaContas"
                v-model="lancamento.id_conta"
                name="conta"
                label="Receber em"
                item-value="id_conta"
                item-text="conta"
                filled
                @change="atrribuirConta(lancamento.id_conta)"
                clearable
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-divider vertical></v-divider>

            <v-col cols="12" xl="1" lg="1" md="2" sm="6" xs="12">
              <v-btn
                small
                block
                dark
                @click="receberLancamento(listaContasReceber)"
                color="bt_receber"
              >
                Receber
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
              <v-data-table
                :headers="headers"
                :items="listaContasReceber"
                :loading="loading_table"
                loading-text="Carregando... Aguarde"
                dense
                :search="search"
                no-data-text="Nenhum resultado encontrado!"
                no-results-text="Nenhum resultado encontrado!"
                class="elevation-1"
              >
                <template v-slot:item.vlr_total="{ item }">
                  <span>{{ formatar_valor(item.vlr_total) }}</span>
                </template>
                <template v-slot:item.data_lancamento="{ item }">
                  <span>{{ formatDate(item.data_lancamento) }}</span>
                </template>
                <template v-slot:item.data_vencimento="{ item }">
                  <span>{{ formatDate(item.data_vencimento) }}</span>
                </template>
                <template v-slot:item.id_conta="{ item, index }">
                  <v-autocomplete
                    :items="listaContas"
                    name="conta"
                    item-value="id_conta"
                    item-text="conta"
                    :value="valorSel"
                    filled
                    clearable
                    :name="`id_conta_${Math.random()}`"
                    @change="addContaItem(item.id_lancamento, index, $event)"
                    hide-details
                  ></v-autocomplete>
                </template>

                <!-- <template v-slot:item.acao="{ item }">
                  <span>
                    <v-btn
                      class="mx-0"
                      @click="showPagar(item)"
                      icon
                      color="primary"
                    >
                      <v-icon>
                        mdi-currency-usd-circle
                      </v-icon>
                    </v-btn></span
                  >
                </template>-->
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogPagar" max-width="600px">
      <v-card>
        <v-card-title class=""
          >Confirma o Recebimento Deste Lançamento?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closePagar">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="pagarUnico()">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogItens" ref="janelaItens" max-width="1185px">
      <DialogItensLancamento
        ref="dialogIt"
        :id_pai="id_pai"
        @fechouDialog="dialogItens = false"
      ></DialogItensLancamento>
    </v-dialog>
  </v-main>
</template>
<script>
var moment = require("moment");
import Saldos from "./Saldos.vue";
import { formata } from "../plugins/format";
import DialogItensLancamento from "./DialogItensLancamento.vue";
import SaldoInativo from "./SaldoInativo.vue";
const auth = {
  headers: {
    Authorization: "bearer " + window.sessionStorage.getItem("token"),
  },
};
export default {
  name: "TabContasPeceber",
  mixins: [formata],
  components: { DialogItensLancamento, SaldoInativo },
  props: {
    source: String,
  },
  mounted() {
    this.buscaSaldos();
    this.buscaContas();
  },
  data: (vm) => ({
    tipo_data: "vencimento",
    dialogItens: false,
    id_pai: "",
    lancamento: {
      descricao: "",
      id_fin_grupo_departamento: "",
      vlr_valor: "",
      data_pagamento: moment().format("YYYY-MM-DD"),
      data_inicial: moment().format("YYYY-MM-01"),
      data_final: moment().format("YYYY-MM-DD"),

      id_conta: "",
    },
    selDel: "",
    dialogDelete: false,
    barSaldos: true,
    barTabela: false,
    isLoading: false,
    // date_ini: moment().format("YYYY-MM-01"),
    //date_vencimento: new Date().toISOString().substr(0, 10),
    //date_pagamento: new Date().toISOString().substr(0, 10),

    datePagamentoFormatted: moment().format("DD/MM/YYYY"),
    dateVencimentoFormatted: "",
    dateIniFormatted: moment().format("YYYY-MM-DD"),
    dateInicialFormatted: moment().format("01/MM/YYYY"),
    dateFinalFormatted: moment().format("DD/MM/YYYY"),
    menu1: false,
    menu2: false,
    menu3: false,
    menuFin: false,
    loading_table: false,
    headers: [],
    search: "",
    loading_filtrar: false,
    loading_baixar: false,
    grupos: [],
    lancpai: [],
    historicos: [],
    fornecedores: [],
    listaTipoDoc: [],

    saldos: [],
    listaContasReceber: [],
    dialogPagar: false,
    selPagar: "",
    valorSel: "",
    headers: [
      {
        text: "Nº",
        sortable: true,
        align: "left",
        value: "id_lancamento",
      },
      {
        text: "Descrição",
        value: "descricao",
      },
      {
        text: "Identif.",
        value: "identif",
        align: "left",
      },
      {
        text: "Data",
        value: "data_lancamento",
        align: "right",
      },
      {
        text: "Valor",
        value: "vlr_total",
        align: "right",
      },

      {
        text: "Vencimento",
        value: "data_vencimento",
        align: "right",
      },
      {
        text: "Tipo",
        value: "tipo",
        align: "left",
      },
      {
        text: "Documento",
        value: "documento",
        align: "left",
      },
      {
        text: "Forma Pagto",
        value: "id_conta",
        align: "left",
      },
    ],
    listaContas: [],
  }),
  computed: {
    dateInFormatted() {
      return this.formatDate(this.lancamento.data_lancamento);
    },
    /*dateVencimentoFormatted() {
      return this.formatDate(this.lancamento.data_vencimento);
    },*/
  },

  watch: {
    "lancamento.data_inicial"(val) {
      this.dateInicialFormatted = this.formatDate(this.lancamento.data_inicial);
    },
    "lancamento.data_final"(val) {
      this.dateFinalFormatted = this.formatDate(this.lancamento.data_final);
    },
    "lancamento.data_pagamento"(val) {
      this.datePagamentoFormatted = this.formatDate(
        this.lancamento.data_pagamento
      );
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogItens(val) {
      if (val == true) {
        this.buscaDadosItens();
      }
    },
  },
  methods: {
    filtrarData() {
      this.filtroBuscar();
    },
    zeraCampos() {
      //   alert("campos");
      this.lancamento.id_conta = "";
      this.valorSel = "";
    },
    mudaTab(tab) {
      this.$emit("mudaTab", tab);
    },
    atrribuirConta(val) {
      this.valorSel = val;
      for (let [key, value] of Object.entries(this.listaContasReceber)) {
        //console.log(`${key}: ${value}`);
        this.listaContasReceber[key].id_conta = val;
        //this.listaContasPagar[key].data_pagamento = val;
      }
    },
    addContaItem(id_lancamento, index, e) {
      // this.lancSelPagar.push({ id_lanc: id_lancamento, conta: e });
      //alert(id_lancamento);
      //  alert(e.target.value);
      // console.log("indkkk" + index);
      // console.log(this.lancSelPagar);
      this.listaContasReceber[index].id_conta = e;
    },
    buscaContas(val) {
      this.listaContas = [];
      axios
        .post(
          `/fin/conta/lista`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
          auth
        )
        .then((response) => {
          this.listaContas = response.data;
        });
    },
    buscaDadosItens() {
      setTimeout(
        () => this.$refs.dialogIt.buscaLancamentosFilhos(this.id_pai),
        300
      );
    },
    openFilhos(id_pai) {
      this.id_pai = id_pai;
      setTimeout(() => (this.dialogItens = true), 200);
    },
    closePagar() {
      this.dialogPagar = false;
    },
    showPagar(item) {
      if (
        this.lancamento.data_pagamento == "" ||
        this.lancamento.data_pagamento == null
      ) {
        alert("informe a data de recebimento");
        return false;
      }
      this.selPagar = item;
      console.log(this.selPagar);
      this.dialogPagar = true;
    },
    pagarUnico() {
      axios
        .post(
          `/fin/lancamento/pagarUm`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            id_lancamento: this.selPagar.id_lancamento,
            vlr_pago: this.selPagar.vlr_inicial,
            pai: this.selPagar.pai,
            data_pagamento: this.lancamento.data_pagamento,
            id_conta: this.lancamento.id_conta,
          },
          auth
        )
        .then((response) => {
          console.log(response);
          this.dialogPagar = false;
          this.buscaSaldos();
          this.buscaContasReceber();
          //this.lancpai = response.data;
        });
    },
    receberLancamento(itens) {
      if (
        this.lancamento.data_pagamento == "" ||
        this.lancamento.data_pagamento == null
      ) {
        alert("informe a data de recebimento!");
        return false;
      }
      let lancs = [];
      console.log(itens);
      //return false;
      for (var it in itens) {
        if (itens[it].id_conta != null && itens[it].id_conta != 5) {
          var dataObj = {
            id_lancamento: itens[it].id_lancamento_item,
            id_lanc: itens[it].id_lancamento,
            vlr_pago: itens[it].vlr_total,
            id_conta: itens[it].id_conta,
          };
          lancs.push(dataObj);
        }
      }

      console.log("valor" + this.valorSel);
      setTimeout(() => {
        this.valorSel = null;
      }, 500);
      //console.log(lancs);
      axios
        .post(
          `/fin/lancamento/pagar`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            dados: lancs,
            data_pagamento: this.parseDate(this.datePagamentoFormatted),
          },
          auth
        )
        .then((response) => {
          console.log(response);
          this.buscaSaldos();
          this.buscaContasReceber();

          //this.lancpai = response.data;
        });
    },
    deleteItem(item) {
      //console.log(item);
      //console.log(item.id_lancamento);
      //this.editedIndex = this.lancamento.indexOf(item);
      //this.editedItem = Object.assign({}, item);
      this.selDel = item.id_lancamento;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.listaLancamentos.splice(this.editedIndex, 1);
      this.closeDelete();
      axios
        .post(
          `/fin/lancamento/remove`,
          { id: this.selDel, cnpj: window.sessionStorage.getItem("cnpj") },
          auth
        )
        .then((response) => {
          this.selDel = "";
          this.buscaContasReceber();
          console.log(response);
          //this.grupos = response.data;
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    editItem(item) {
      this.lancamento = item;
    },
    formata_moeda(val) {
      let valor = this.formatar_moeda(val);
      return valor;
    },
    addDescricao(val) {
      this.lancamento.descricao = val;
    },
    selecionarConta(id) {
      this.lancamento.id_conta = id;
      // sessionStorage.setItem("selconta", id);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getStatus(status) {
      if (status === "Cancelada") {
        return "cancelada";
      }
    },

    buscaGrupos() {
      axios
        .post(
          `/fin/grupo/listaOp`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            tipo: "C",
          },
          auth
        )
        .then((response) => {
          this.grupos = response.data;
        });
    },
    selConta(e) {
      //console.log(e);
      this.lancamento.id_conta = e;
    },
    openreceber() {
      alert("receber");
    },
    buscaSaldos() {
      this.$refs.saldos.buscaSaldos();
    },
    filtroBuscar() {
      setTimeout(() => this.buscaContasReceber(), 500);
    },
    buscaContasReceber() {
      /*if (
        this.lancamento.data_vencimento == "" ||
        this.lancamento.data_vencimento == null
      ) {
        return false;
      }*/
      /* if (
        this.lancamento.id_fin_grupo_departamento == "" ||
        this.lancamento.id_fin_grupo_departamento == null
      ) {
        return false;
      }*/
      //this.barTabela = true;
      this.loading_table = true;
      axios
        .post(
          `/fin/lancamento/contas_receber`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            data_inicial: this.lancamento.data_inicial,
            data_final: this.lancamento.data_final,
            tipo_data: this.tipo_data,
          },
          auth
        )
        .then((response) => {
          this.listaContasReceber = response.data;
          //this.barTabela = false;
          this.loading_table = false;
        });
    },
    buscaTipoDoc() {
      axios
        .post(
          `/fin/tipo_doc/lista`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
          auth
        )
        .then((response) => {
          this.listaTipoDoc = response.data;
        });
    },
  },
};
</script>
<style scoped>
.ativa {
  border: 2px solid #000 !important;
}
</style>
