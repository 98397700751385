<template>
  <div
    style="width:100%;padding-top: 0px;
    padding-bottom: 0px;margin:0px"
  >
    <v-card class="pa-1" max-width="100%" elevation="0">
      <v-progress-linear
        v-show="barSaldos"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-sheet class="mx-auto" elevation="0" max-width="100%">
        <v-slide-group
          v-model="selectedItem"
          class="pa-1"
          mandatory
          show-arrows
        >
          <v-slide-item
            v-for="(item, i) in saldos"
            :key="i"
            v-slot="{ active, toggle }"
            :class="item.cor"
          >
            <v-card
              class="ma-1"
              style="padding-top:2px!important"
              :class="active ? 'ativa' : 'desativado'"
              height="80"
              width="170px"
              @click="toggle"
            >
              <v-card-text
                ><div class="pa-0">
                  <h4 style="color:#fff; font-size:0.85em">
                    {{ item.nome_banco }}
                  </h4>
                  <p style="color:#fff;font-size:0.80em;line-height:5px">
                    {{ item.conta }}
                  </p>
                  <h4
                    style="color:#fff;text-shadow: 0.08em 0 0 #666, -0.02em 0 0 #666, 0 0.0em 0 #666, 0 -0.02em 0 #000, 0.05em 0.10em #000, -0.02em -0.02em 0 #000, 0.00em -0.02em 0 #000, -0.01em 0.01em 0 #000;font-size:1.2em"
                  >
                    {{ formatar_valor(item.saldo) }}
                  </h4>

                  <h6
                    style="color:#fff;font-size:0.85em;line-height:15px"
                    v-show="item.saldo_pend != 0"
                  >
                    pendente: {{ formatar_valor(item.saldo_pend) }}
                  </h6>
                </div></v-card-text
              >

              <v-row gutters="1" class="fill-height">
                <v-scale-transition>
                  <!--<v-icon
                    v-if="active"
                    color="green"
                    size="25"
                    style="margin-top:-75px;margin-left:80%"
                    v-text="'mdi-cash-check'"
                  ></v-icon>-->
                </v-scale-transition>
              </v-row>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </v-card>
  </div>
</template>

<script>
import { formata } from "../plugins/format";

const auth = {
  headers: {
    Authorization: "bearer " + window.sessionStorage.getItem("token"),
  },
};
export default {
  props: {
    source: String,
  },
  mixins: [formata],
  mounted() {
    this.buscaSaldos();
    sessionStorage.setItem("selconta", "3");
  },
  data: (vm) => ({
    barSaldos: true,
    saldos: [],
    selectedItem: 0,
  }),
  computed: {},

  watch: {
    selectedItem(val) {
      //alert();
      this.$emit("selecionaConta", this.saldos[val].id_conta);
      //  this.$emit("changeTab", "tab1");
      sessionStorage.setItem("selconta", this.saldos[val].id_conta);
      sessionStorage.setItem("selindex", val);
      sessionStorage.setItem("conta", this.saldos[val].nome_banco);
    },
  },
  methods: {
    selecionarConta(conta, index) {
      console.log("conta=" + conta);
      console.log("index=" + index);
      // this.$emit("changeTab", "tab1");
      //console.log("index " + index);
      // this.$emit("selecionaConta", id);
      setTimeout(() => (this.selectedItem = parseInt(index)), 5);
      //  sessionStorage.setItem("selconta", id);
      //  sessionStorage.setItem("selindex", index);
    },
    buscaSaldos() {
      axios
        .post(
          `/fin/conta/saldos`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
          auth
        )
        .then((response) => {
          this.saldos = response.data.lista;

          if (sessionStorage.getItem("selconta") != "") {
            this.selecionarConta(
              sessionStorage.getItem("selconta"),
              sessionStorage.getItem("selindex")
            );
          } else {
            this.selecionarConta(3, 1);
          }

          this.barSaldos = false;
        });
    },
  },
};
</script>
<style scoped>
.cancelada {
  color: red;
}
.ativa {
  border-radius: 10px !important;
}
.desativado {
  opacity: 0.4;
  border-radius: 10px !important;
}
.v-slide-group__next,
.v-slide-group__prev {
  min-width: 5px !important;
  flex: 0 1 30px !important;
}
.container {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
</style>
