<template>
  <v-main class="pa-2">
    <v-row>
      <v-col
        cols="12"
        xl="12"
        lg="12"
        md="12"
        sm="12"
        xs="12"
        class="colunaSaldo"
      >
        <Saldos
          ref="saldos"
          @changeTab="mudaTab"
          @selecionaConta="selConta"
          :conta="conta"
        ></Saldos>
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-alert class="error" v-if="!lancamento.descricao.required">
        Descrição é requerida.
      </v-alert>
    </v-row>-->
    <v-row gutters="1" class="pa-0">
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
        <v-card class="pa-5 pt-2" max-width="100%" elevation="2">
          <v-row class="mt-0">
            <v-col cols="12" xl="2" lg="3" md="3" sm="3" xs="3">
              <v-autocomplete
                :items="historicos"
                v-model="thistorico"
                name="historico"
                label="Histórico Padrão"
                item-value="descricao"
                item-text="descricao"
                :auto-select-first="true"
                value
                filled
                clearable
                hide-details
                @focus="
                  ($event) => {
                    $event.target.click();
                  }
                "
                @keyup.enter="mudaCampo('fornecedor')"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="2" lg="3" md="3" sm="3" xs="3">
              <v-autocomplete
                :items="fornecedores"
                v-model="lancamento.fornecedor"
                name="fornecedor"
                ref="fornecedor"
                label="Fornecedor"
                item-value="id_fornecedor"
                item-text="nome"
                @keyup.enter="mudaCampo('descricao')"
                @focus="
                  ($event) => {
                    $event.target.click();
                  }
                "
                return-object
                filled
                clearable
                class="text-md-right"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="3">
              <v-text-field
                v-model="lancamento.descricao"
                ref="descricao"
                name="Descricao"
                label="Descrição"
                value
                filled
                clearable
                :name="`descricao_${Math.random()}`"
                hide-details
                @keyup.enter.native="mudaCampo('documento')"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mt-5">
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="3">
              <v-text-field
                v-model="lancamento.documento"
                name="documento"
                label="Documento"
                value
                clearable
                filled
                :name="`documento_${Math.random()}`"
                autocomplete="false"
                hide-details
                @keyup.enter="mudaCampo('tipo_doc')"
                ref="documento"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="3">
              <v-autocomplete
                :items="listaTipoDoc"
                v-model="lancamento.id_tipo_doc"
                name="tipo_doc"
                label="Tipo"
                item-value="id_tipo_doc"
                item-text="descricao"
                value
                filled
                :auto-select-first="true"
                clearable
                hide-details
                @change="mudaCampo('data_lancamento')"
                @keyup.enter.native="mudaCampo('data_lancamento')"
                @focus="
                  ($event) => {
                    $event.target.click();
                  }
                "
                ref="tipo_doc"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" xl="2" lg="2" md="3" sm="4" xs="3">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateIniFormatted"
                    label="Lançamento"
                    prepend-inner-icon="mdi-calendar"
                    ref="data_lancamento"
                    filled
                    readonly
                    @blur="
                      lancamento.data_lancamento = parseDate(dateIniFormatted)
                    "
                    v-on="on"
                    @keydown.up="upDl"
                    @keydown.down="downDl"
                    @keydown.left="leftDl"
                    @keydown.right="rightDl"
                    @keydown.enter="dateSelected('data_vencimento', 'menu1')"
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  format="dd/MM/yyyy"
                  scrollable
                  v-model="lancamento.data_lancamento"
                  no-title
                  @click:date="dateSelected('data_vencimento', 'menu1')"
                  @change="$emit('input', $event)"
                  ref="dtpk_lancamento"
                  hide-details
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" xl="2" lg="2" md="3" sm="4" xs="3">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateVencimentoFormatted"
                    label="Vencimento"
                    prepend-inner-icon="event"
                    filled
                    readonly
                    ref="data_vencimento"
                    v-on="on"
                    @blur="
                      lancamento.data_vencimento = parseDate(
                        dateVencimentoFormatted
                      )
                    "
                    @keydown.up="upDv"
                    @keydown.down="downDv"
                    @keydown.left="leftDv"
                    @keydown.right="rightDv"
                    @keydown.enter="dateSelected('data_pagamento', 'menu2')"
                    @click:clear="lancamento.data_vencimento = ''"
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  format="dd/MM/yyyy"
                  v-model="lancamento.data_vencimento"
                  no-title
                  @click:date="dateSelected('data_pagamento', 'menu2')"
                  @change="$emit('input', $event)"
                  ref="dtpk_vencimento"
                  hide-details
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col
              v-show="showDtpg"
              cols="12"
              xl="2"
              lg="2"
              md="3"
              sm="4"
              xs="3"
            >
              <v-menu
                ref="menu3"
                v-model="menu3"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="datePagamentoFormatted"
                    label="Pagamento"
                    persistent-hint
                    prepend-inner-icon="event"
                    ref="data_pagamento"
                    filled
                    v-on="on"
                    @blur="date_pagamento = parseDate(datePagamentoFormatted)"
                    @keydown.up="upDp"
                    @keydown.down="downDp"
                    @keydown.left="leftDp"
                    @keydown.right="rightDp"
                    @keydown.enter.prevent.stop="
                      dateSelected2('valor', 'menu3')
                    "
                    hide-details
                    clearable
                    @click:clear="lancamento.data_pagamento = ''"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  v-model="lancamento.data_pagamento"
                  no-title
                  ref="dtpk_pagamento"
                  @click:date="dateSelected2('valor', 'menu3')"
                  @change="$emit('input', $event)"
                  hide-details
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col v-show="false" cols="6" xl="2" lg="2" md="2" sm="2" xs="2">
              <v-text-field
                v-model="itemUm.parcelas"
                name="parcelas"
                label="Parcelas"
                value
                filled
                clearable
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
              <v-switch
                inset
                flat
                dense
                hide-details=""
                v-model="multiplo"
                :label="`Multiplos Grupos Financeiro`"
                @change="alterMultiplos()"
              ></v-switch>
            </v-col>
          </v-row>

          <v-row v-show="!multiplo">
            <v-col cols="12" xl="2" lg="2" md="2" sm="4" xs="4">
              <v-text-field
                v-model="itemUm.vlr_valor"
                name="valor"
                label="Valor"
                ref="valor"
                value
                @keydown.enter="mudaCampo2('grupo_financeiro')"
                @focus="
                  ($event) => {
                    $event.target.click();
                  }
                "
                filled
                dense
                v-mask-decimal.br="2"
                prepend-inner-icon="attach_money"
                clearable
                hide-details
                :name="`valor_${Math.random()}`"
                autocomplete="false"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="8" xs="5">
              <v-autocomplete
                :items="grupos"
                v-model="itemUm.grupo_departamento"
                name="grupo_financeiro"
                ref="grupo_financeiro"
                label="Grupo Financeiro"
                item-value="id_grupo_dep"
                item-text="descricao"
                @keydown.enter="mudaCampo('observacao1')"
                return-object
                filled
                clearable
                hide-details
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" xl="7" lg="7" md="7" sm="10" xs="12">
              <v-text-field
                v-model="itemUm.observacao"
                name="observacao"
                ref="observacao1"
                label="Observação"
                value
                filled
                clearable
                @keydown.enter="salvaLancamento()"
                :name="`obs_${Math.random()}`"
                autocomplete="false"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-show="multiplo">
            <v-col cols="12" xl="12" lg="12" md="12" sm="4" xs="3">
              <v-spacer></v-spacer>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row v-show="multiplo">
            <v-col cols="12" xl="12" lg="12" md="12" sm="10" xs="10">
              <itens-lancamento
                ref="item0"
                @newData="addDadosComponet($event)"
                @addItem="addItem($event)"
                :primeiro="true"
                :itensIn="itensIn"
              ></itens-lancamento>
            </v-col>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="6">
              <div
                class="success"
                style="font-size:15px;color:#fff;text-align:left"
              >
                Grupos Financeiros deste lançamento
              </div>
            </v-col>

            <v-col
              class="text-left"
              cols="12"
              xl="12"
              lg="12"
              md="12"
              sm="12"
              xs="12"
            >
              <v-chip
                class="ml-2"
                v-if="tag.aberto"
                v-for="(tag, index) in lancamento.itensLancamento"
                color=""
                :key="index"
                v-model="tag.aberto"
                @click:close="
                  tag.aberto = !tag.aberto;
                  excluiItem(index);
                "
                close
                label
                outlined
              >
                {{ tag.grupo_departamento.descricao }} -
                {{ tag.vlr_valor }} </v-chip
              >.
            </v-col>
          </v-row>
          <!-- <v-row>
              <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                <template v-for="(child, index) in children">
                  <component
                    @removeComponente="removeItem(index)"
                    :is="child"
                    :key="index"
                    :item="novoItem"
                  ></component>
                </template>
              </v-col>
            </v-row>-->
          <!--<v-col v-show="multiplo" cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
              <template v-for="(child, index) in children">
                <component
                  :primeiro="false"
                  @removeComponente="removeItem(index)"
                  :is="child"
                  :key="index"
                  @newData="addDadosComponet($event)"
                ></component>
              </template>
            </v-col>-->

          <v-row>
            <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12">
              <v-btn
                block
                ref="btsalvar"
                dark
                @click="salvaLancamento()"
                color="success"
                :loading="salvando"
              >
                <v-icon>
                  mdi-content-save-outline
                </v-icon>
                Salvar
              </v-btn>
            </v-col>
            <v-col v-show="editar" cols="12" xl="1" lg="1" md="2" sm="6" xs="2">
              <v-btn @click="cancelEdit()" color="warning">
                Cancelar
              </v-btn>
            </v-col>
          </v-row>

          <!-- <v-row>
                  <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="3">
                    <v-data-table
                      :headers="headersItens"
                      :items="lancamento.itensLancamento"
                      :loading="false"
                      no-data-text="Nenhum item incluido!"
                      no-results-text="Nenhum item incluido!"
                      class="elevation-1"
                    >
                    </v-data-table>
                  </v-col>
                </v-row>-->

          <!--   <v-col cols="6" xl="2" lg="3" md="3" sm="3" xs="3">
              <v-btn
                block
                small
                color="success"
                @click="dialogIncluirItens = true"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                Adicionar Itens</v-btn
              >
            </v-col>-->
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
        <v-card class="mt-0">
          <v-data-table
            :headers="headersLanc"
            :items="listaLancamentos"
            :loading="loading_table"
            loading-text="Carregando... Aguarde"
            dense
            :search="search"
            no-data-text="Nenhum resultado encontrado!"
            no-results-text="Nenhum resultado encontrado!"
            class="elevation-3 mt-0"
          >
            <template class="pa-5" v-slot:top>
              <v-row class="mb-1 pa-5">
                <v-col cols="12" xl="2" lg="2" md="2" sm="2" xs="3">
                  <p style="margin-left:5px;font-size:1.5em">
                    Últimos Lançamentos
                  </p>
                  <span style="font-weight:700" v-show="!todasContas">{{
                    conta
                  }}</span>
                  <span style="font-weight:700" v-show="todasContas"
                    >Todas Contas
                  </span>
                </v-col>
                <v-col cols="12" xl="2" lg="2" md="2" sm="2" xs="3">
                  <v-menu
                    v-model="menuIN"
                    :close-on-content-click="false"
                    transition="scroll-y-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="dateInicialFormatted"
                        label="Data inicial Lançamento"
                        persistent-hint
                        prepend-inner-icon="event"
                        filled
                        hide-details
                        readonly
                        v-on="on"
                        @blur="data_inicial = parseDate(dateInicialFormatted)"
                        @click:clear="data_inicial = ''"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="pt-br"
                      format="dd/MM/yyyy"
                      v-model="data_inicial"
                      no-title
                      title="inicial"
                      @input="
                        menuIN = false;
                        filtroData();
                      "
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" xl="2" lg="2" md="2" sm="2" xs="3">
                  <v-menu
                    v-model="menuFin"
                    :close-on-content-click="false"
                    transition="scroll-y-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="dateFinalFormatted"
                        label="Data Final Lançamento"
                        persistent-hint
                        prepend-inner-icon="event"
                        filled
                        readonly
                        hide-details
                        v-on="on"
                        @blur="data_final = parseDate(dateFinalFormatted)"
                        @click:clear="data_final = ''"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="pt-br"
                      v-model="data_final"
                      no-title
                      @input="
                        menuFin = false;
                        filtroData();
                      "
                      hide-details
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" xl="2" lg="2" md="3" sm="3" xs="3">
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Pesquisar"
                    solo
                    color="success"
                    background-color="inputColor"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="4" xl="2" lg="2" md="3" sm="6" xs="12">
                  <v-switch
                    inset
                    flat
                    dense
                    hide-details=""
                    v-model="todasContas"
                    :label="`Todas Contas`"
                    @change="switchContas()"
                  ></v-switch>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                color="primary"
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon color="error" small @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:item.vlr_total="{ item }">
              <span>{{ formatar_valor(item.vlr_total) }}</span>
            </template>
            <template v-slot:item.data_lancamento="{ item }">
              <span>{{ formatDate(item.data_lancamento) }}</span>
            </template>
            <template v-slot:item.data_vencimento="{ item }">
              <span>{{ formatDate(item.data_vencimento) }}</span>
            </template>
            <template v-slot:item.data_baixa="{ item }">
              <span>{{ formatDate(item.data_baixa) }}</span>
            </template>
            <!--<template v-slot:item.pai="{ item }">
              <div v-if="item.pai">
                <v-btn
                  icon
                  color="error"
                  @click="openFilhos(item.pai)"
                  fab
                  x-small
                >
                  <v-icon>mdi-link-variant</v-icon>
                </v-btn>
              </div>
            </template>-->
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDelete" max-width="600px">
      <v-card>
        <v-card-title class=""
          >Tem certeza que deseja excluir este Lançamento?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="3000"
      :color="corMsg"
      :multi-line="'multi-line'"
      :vertical="'vertical'"
      :top="true"
      v-model="mostraMsg"
    >
      {{ textSnack }}
    </v-snackbar>

    <!--    <Mensagem ></Mensagem>-->
  </v-main>
</template>
<script>
var moment = require("moment");
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

import { formata } from "../plugins/format";
import ItensLancamento from "./ItensLancamento.vue";
import Chips from "./Chips.vue";
import Saldos from "./Saldos.vue";

const auth = {
  headers: {
    Authorization: "bearer " + window.sessionStorage.getItem("token"),
  },
};
export default {
  components: { Saldos, ItensLancamento, Chips },
  name: "MenuLancamento",
  //components: { Mensagem },
  mixins: [formata, validationMixin],

  props: {
    source: String,
  },

  mounted() {
    // this.buscaNotas();

    this.buscaGrupos();
    this.buscaHistoricos();
    this.buscaFornecedores();
    this.mudaCampo("descricao");
    //this.mudaCampo2();
    //this.buscaLancPai();
    // this.buscaSaldos();
    this.buscaLancamentos();
    this.buscaTipoDoc();
    this.lancamento.id_conta = window.sessionStorage.getItem("selconta");
    this.$store.state.valor_item = 0;
  },
  data: () => ({
    todasContas: false,
    editar: false,
    multiplo: false,
    showDtpg: true,
    children: [],
    singleSelect: false,
    tudoPreenchido: false,
    searchInput: "",
    /*headersItens: [
      {
        text: "Descricao",
        align: "left",
        value: "descricao",
      },
      {
        text: "Grupo",
        value: "grupo_departamento.descricao",
      },
      {
        text: "Valor",
        value: "vlr_valor",
        align: "left",
      },
      {
        text: "Data Lançamento",
        value: "dt_lancamento",
      },
      {
        text: "Data vencimento",
        value: "dt_vencimento",
      },
    ],*/
    dialogIncluirItens: false,
    lancamento: {
      descricao: "",
      data_lancamento: moment().format("YYYY-MM-DD"),
      data_vencimento: moment().format("YYYY-MM-DD"),
      data_pagamento: moment().format("YYYY-MM-DD"),
      documento: "",
      observacao: "",
      id_tipo_doc: "",
      fornecedor: { id_fornecedor: "", nome: "" },

      itensLancamento: [],
    },

    data_inicial: moment().format("YYYY-MM-01"),
    data_final: moment().format("YYYY-MM-DD"),
    data_atual: moment().format("YYYY-MM-DD"),
    chip1: true,
    dialogItens: false,
    selDel: "",
    mostraMsg: false,
    cor: "",
    textSnack: "",
    corMsg: "",
    dialogDelete: false,

    barTabela: true,
    isLoading: false,
    // date_ini: moment().format("YYYY-MM-01"),ser
    //date_vencimento: new Date().toISOString().substr(0, 10),
    //date_pagamento: new Date().toISOString().substr(0, 10),

    dateIniFormatted: moment().format("DD/MM/YYYY"),
    dateInicialFormatted: moment().format("01/MM/YYYY"),
    dateFinalFormatted: moment().format("DD/MM/YYYY"),
    dateVencimentoFormatted: moment().format("DD/MM/YYYY"),
    datePagamentoFormatted: moment().format("DD/MM/YYYY"),
    menu1: false,
    menu1: false,
    menu2: false,
    menu3: false,
    menuFin: false,
    menuIN: false,
    menuVen: false,
    loading_table: false,

    search: "",
    loading_filtrar: false,
    loading_baixar: false,
    grupos: [],

    historicos: [],
    thistorico: "",
    fornecedores: [],
    listaTipoDoc: [],
    selectedItem: 0,
    error: "error",
    listaLancamentos: [],
    salvando: false,
    headersLanc: [
      {
        text: "Nº",
        sortable: true,
        align: "left",
        value: "id_lancamento",
      },
      {
        text: "Descrição",
        value: "descricao",
      },
      {
        text: "Identif.",
        value: "identif",
        width: "5%",
        align: "left",
      },
      {
        text: "Data",
        value: "data_lancamento",
        align: "right",
      },

      {
        text: "Valor",
        value: "vlr_total",
        align: "right",
      },

      {
        text: "Vencimento",
        value: "data_vencimento",
        align: "right",
      },
      {
        text: "Tipo",
        value: "tipo",
        align: "left",
      },
      {
        text: "Documento",
        value: "documento",
        align: "left",
      },
      {
        text: "Data Baixa",
        value: "data_baixa",
        align: "left",
      },
      {
        text: "Conta",
        value: "conta",
        width: "200px",
        align: "left",
      },
      { text: "Ações", value: "actions", sortable: false },
    ],
    itemUm: {
      // descricao: "",
      grupo_departamento: { id_grupo_dep: "", descricao: "" },
      vlr_valor: "",
      // data_vencimento: "",
      //  data_lancamento: new Date().toISOString().substr(0, 10),
      //  dt_vencimento: "",
      // dt_lancamento: vm.formatDate(new Date().toISOString().substr(0, 10)),
      observacao: "",
      //  id_conta: "",
    },
    novoItem: "",
    conta: "Caixa Central",
    tags: [
      {
        id: 1,
        text: "Chip #1",
        isOpen: true,
      },
      {
        id: 2,
        text: "Chip #2",
        isOpen: true,
      },
    ],
    validations: {
      lancamento: {
        descricao: {
          required,
          minLength: minLength(3),
        },
        data_vencimento: {
          required,
        },
        documento: {
          required,
        },
      },
    },
    itensIn: [],
  }),
  computed: {
    dateInFormatted() {
      return this.formatDate(this.lancamento.data_lancamento);
    },

    /* dateVencimentoFormatted() {
      return this.formatDate(this.lancamento.data_vencimento);
    },
    datePagamentoFormatted() {
      return this.formatDate(this.lancamento.data_pagamento);
    },*/
  },

  watch: {
    "lancamento.descricao"() {
      let desc = this.lancamento.descricao.trim();
      // console.log(this.lancamento.descricao);
      if (desc != undefined) {
        this.lancamento.descricao = desc;
      }
    },

    "lancamento.fornecedor"(newVal, oldVal) {
      console.log("newF" + newVal);
      console.log("velhF" + oldVal.nome);
      let velhaStr = "";

      try {
        // let novo = "";
        if (oldVal != null) {
          if (oldVal.nome == "" || oldVal.nome == undefined) {
            console.log("aqui1");
            if (newVal != "" && newVal != null) {
              this.lancamento.descricao =
                this.lancamento.descricao + " " + newVal.nome;
            } else {
              //this.lancamento.descricao = "";
            }
            this.lancamento.descricao.trim();
          } else {
            console.log("aqui2");
            velhaStr = this.lancamento.descricao;
            this.lancamento.descricao = velhaStr.replace(
              oldVal.nome,
              newVal.nome
            );
            //     this.lancamento.descricao.trim();
          }
        } else {
          console.log("aqui3");
          this.lancamento.descricao =
            this.lancamento.descricao + " " + newVal.nome;
        }
        //  this.lancamento.descricao.trim();
      } catch (e) {
        velhaStr = this.lancamento.descricao;
        this.lancamento.descricao = velhaStr.replace(oldVal.nome, "");
        // this.lancamento.descricao.trim();
        //console.log(e); // passa o objeto de exceção para o manipulador de erro
      }
    },
    thistorico(newVal, oldVal) {
      // let novo = "";
      let veStr = "";
      this.lancamento.descricao.trim();
      if (oldVal == "" || oldVal == null) {
        this.lancamento.descricao = newVal + " " + this.lancamento.descricao;
      } else {
        veStr = this.lancamento.descricao;
        if (newVal != null) {
          this.lancamento.descricao = veStr.replace(oldVal, newVal);
        } else {
          this.lancamento.descricao = veStr.replace(oldVal, "");
        }
      }
      this.lancamento.descricao.trim();
    },
    data_inicial(val) {
      this.dateInicialFormatted = this.formatDate(this.data_inicial);
    },
    data_final(val) {
      this.dateFinalFormatted = this.formatDate(this.data_final);
    },

    "lancamento.data_lancamento"(val) {
      this.dateIniFormatted = this.formatDate(this.lancamento.data_lancamento);
      this.lancamento.dt_lancamento = this.dateIniFormatted;
    },
    "lancamento.data_vencimento"(val) {
      this.dateVencimentoFormatted = this.formatDate(
        this.lancamento.data_vencimento
      );
      this.lancamento.dt_vencimento = this.dateVencimentoFormatted;
    },
    "lancamento.data_pagamento"(val) {
      this.datePagamentoFormatted = this.formatDate(
        this.lancamento.data_pagamento
      );
    },
    "itemUm.data_descricao"(valdesc) {},
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogItens(val) {
      if (val == true) {
        this.buscaDadosItens();
      }
    },
  },
  methods: {
    toggleMenu(show = null, emit = true) {
      show = show !== null ? show : !this.menu1;

      if (show === this.menu1) {
        return;
      }

      this.menu1 = show;

      if (emit) {
        this.$emit("update:show", show);
      }
    },
    dateSelected2(data, menu) {
      if (menu == "menu3") {
        this.menu3 = false;
      }
      if (this.multiplo) {
        this.$refs.item0.$refs.valori.focus();
      } else {
        this.mudaCampo2("valor");
      }
    },
    dateSelected(data, menu) {
      if (menu == "menu1") {
        this.toggleMenu(false);
      }
      if (menu == "menu2") {
        //this.toggleMenu(false);
        this.menu2 = false;
      }
      if (menu == "menu3") {
        //this.toggleMenu(false);
        this.menu3 = false;
      }

      this.mudaCampo(data);

      this.$emit("date-selected");
    },

    // keyboard navigation

    pickModifiedDl(modifier) {
      this.$refs.dtpk_lancamento.dateClick(
        modifier(moment(this.$refs.dtpk_lancamento.inputDate)).format(
          "YYYY-MM-DD"
        )
      );
    },
    pickModifiedDv(modifier) {
      this.$refs.dtpk_vencimento.dateClick(
        modifier(moment(this.$refs.dtpk_vencimento.inputDate)).format(
          "YYYY-MM-DD"
        )
      );
    },
    pickModifiedDp(modifier) {
      this.$refs.dtpk_pagamento.dateClick(
        modifier(moment(this.$refs.dtpk_pagamento.inputDate)).format(
          "YYYY-MM-DD"
        )
      );
    },

    upDl() {
      this.pickModifiedDl((m) => m.subtract(1, "weeks"));
    },

    downDl() {
      this.pickModifiedDl((m) => m.add(1, "weeks"));
    },

    leftDl(e) {
      this.pickModifiedDl((m) =>
        e.shiftKey ? m.subtract(1, "month") : m.subtract(1, "days")
      );
    },

    rightDl(e) {
      this.pickModifiedDl((m) =>
        e.shiftKey ? m.add(1, "month") : m.add(1, "days")
      );
    },

    upDv() {
      this.pickModifiedDv((m) => m.subtract(1, "weeks"));
    },

    downDv() {
      this.pickModifiedDv((m) => m.add(1, "weeks"));
    },

    leftDv(e) {
      this.pickModifiedDv((m) =>
        e.shiftKey ? m.subtract(1, "month") : m.subtract(1, "days")
      );
    },

    rightDv(e) {
      this.pickModifiedDv((m) =>
        e.shiftKey ? m.add(1, "month") : m.add(1, "days")
      );
    },

    upDp() {
      this.pickModifiedDp((m) => m.subtract(1, "weeks"));
    },

    downDp() {
      this.pickModifiedDp((m) => m.add(1, "weeks"));
    },

    leftDp(e) {
      this.pickModifiedDp((m) =>
        e.shiftKey ? m.subtract(1, "month") : m.subtract(1, "days")
      );
    },

    rightDp(e) {
      this.pickModifiedDp((m) =>
        e.shiftKey ? m.add(1, "month") : m.add(1, "days")
      );
    },
    mudaCampo2(campo) {
      if (campo == "valor") {
        this.$refs.valor.focus();
      }

      if (campo == "grupo_financeiro") {
        this.$refs.grupo_financeiro.focus();
      }
    },
    mudaCampo(campo) {
      /*  if (campo == "fornecedor") {
        this.lancamento.descricao = this.thistorico;
        //this.$refs.fornecedor.focus();
      }
      if (campo == "descricao") {
        this.lancamento.descricao =
          this.lancamento.descricao + " " + this.lancamento.fornecedor.nome;
        //this.$refs.data_lancamento.focus();
      }*/

      if (campo != "valor" && campo != "grupo_financeiro") {
        eval("this.$refs." + campo + ".focus()");

        //this.$refs.dtpk_lancamento.showCalendar();
        //this.$refs.item0.$refs.valor.focus();
      }

      /*  if (this.multiplo) {
        //this.$refs.dtpk_lancamento.showCalendar();
        this.$refs.item0.$refs.valori.focus();
      }*/
    },
    mudaTab(tab) {
      // alert(tab);
      //console.log(tab);
      this.$emit("mudaTab", tab);
    },
    addItemLanc(item, it) {
      addItem;
      this.lancamento.itensLancamento.push(items);
    },
    cancelEdit() {
      this.editar = false;

      this.buscaLancamentos();
      this.zeraLancamentos();
      this.zeraItens();
    },
    excluiItem(index) {
      //console.log("log" + index);
      // console.log("valor" + this.lancamento.itensLancamento[index].vlr_valor);
      this.$store.dispatch(
        "alterItem",
        this.lancamento.itensLancamento[index].vlr_valor
      );

      this.lancamento.itensLancamento.splice(index, 1);
    },
    addItem(item) {
      // console.log(item);
      //  let novoItem = item;
      //item.grupo_departamento
      this.$store.dispatch("addItem", item.vlr_valor);

      let novoItem = {
        ...item,
        grupo_departamento: { ...item.grupo_departamento },
      };
      novoItem.id_fin_grupo_departamento = item.grupo_departamento.id_grupo_dep;
      novoItem.aberto = true;
      this.lancamento.itensLancamento.push(novoItem);
      //this.zeraItens();
      //this.searchInput = "";
      // console.log(novoItem);
      this.novoItem = novoItem;
      //this.buscaGrupos();
      this.children.push(Chips);
      this.$refs.item0.$refs.valori.focus();
    },
    removeItem(index) {
      // this.children.splice(index, 1);
    },
    filtroData() {
      setTimeout(() => this.buscaLancamentos(), 300);
    },
    buscaDadosItens() {
      setTimeout(
        () => this.$refs.dialogIt.buscaLancamentosFilhos(this.id_pai),
        300
      );
    },
    openFilhos(id_pai) {
      this.id_pai = id_pai;
      setTimeout(() => (this.dialogItens = true), 200);
    },
    deleteItem(item) {
      //console.log(item);
      //console.log(item.id_lancamento);
      //this.editedIndex = this.lancamento.indexOf(item);
      //this.editedItem = Object.assign({}, item);
      this.selDel = item.id_lancamento;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.listaLancamentos.splice(this.editedIndex, 1);
      this.closeDelete();
      axios
        .post(
          `/fin/lancamento/remove`,
          { id: this.selDel, cnpj: window.sessionStorage.getItem("cnpj") },
          auth
        )
        .then((response) => {
          this.selDel = "";
          this.buscaLancamentos();
          //this.grupos = response.data;
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    switchContas() {
      this.buscaLancamentos();
    },

    editItem(item) {
      if (item.multiplos > 1) {
        this.$store.state.valor_item = item.vlr_total;
        this.multiplo = true;
      } else {
        this.multiplo = false;
      }

      this.lancamento = item;
      this.lancamento.data_pagamento = this.lancamento.data_baixa
      this.editar = true;
      this.itemUm.grupo_departamento = {
        id_grupo_dep: item.id_fin_grupo_departamento,
        descricao: "",
      };
      this.itemUm.vlr_valor = item.vlr_total;
      this.itemUm.observacao = item.observacao;

      //console.log(it);
      // this.lancamento.itensLancamento.push(it);
      //this.itensIn = it;
    },
    formata_moeda(val) {
      let valor = this.formatar_moeda(val);
      return valor;
    },
    addDescricao(val) {
      this.lancamento.descricao = val;
      this.$refs.fornecedor.focus();
    },
    addFornec(old, novo) {
      //console.log(this.lancamento.fornecedor.nome);
      //let oldforn = { ...old };
      // console.log(old.id_fornecedor);

      //this.lancamento.descricao.replace(oldforn.nome, );
      this.lancamento.descricao =
        this.lancamento.descricao + " " + this.lancamento.fornecedor.nome;

      this.$refs.descricao.focus().preventDefault();
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getStatus(status) {
      if (status === "Cancelada") {
        return "cancelada";
      }
    },

    buscaGrupos() {
      axios
        .post(
          `/fin/grupo/lista`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
          auth
        )
        .then((response) => {
          this.grupos = response.data;
        });
    },
    buscaFornecedores() {
      axios
        .post(
          `/fin/fornecedor/lista`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
          auth
        )
        .then((response) => {
          this.fornecedores = response.data;
        });
    },

    /*buscaLancPai() {
      axios
        .post(
          `/fin/lancamento/pais`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
          auth
        )
        .then((response) => {
          this.lancpai = response.data;
        });
    },*/
    buscaHistoricos() {
      axios
        .post(
          `/fin/historico/lista`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
          auth
        )
        .then((response) => {
          this.historicos = response.data;
        });
    },
    buscaLancamentos() {
      let conta;
      this.loading_table = true;
      if (this.todasContas) {
        conta = "";
      } else {
        conta = window.sessionStorage.getItem("selconta");
      }
      axios
        .post(
          `/fin/lancamento/lista`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            id_conta: conta,
            data_inicial: this.data_inicial,
            data_final: this.data_final,
          },
          auth
        )
        .then((response) => {
          this.listaLancamentos = response.data;

          this.barTabela = false;
          this.loading_table = false;
          this.conta = window.sessionStorage.getItem("conta");
        });
    },
    buscaTipoDoc() {
      axios
        .post(
          `/fin/tipo_doc/lista`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
          auth
        )
        .then((response) => {
          this.listaTipoDoc = response.data;
        });
    },
    zeraLancamentos() {
      this.$store.state.valor_item = 0;
      this.lancamento.descricao = "";
      this.lancamento.id_lancamento = "";
      //this.lancamento.vlr_valor = 0;
      // this.lancamento.id_fin_grupo_departamento = "";

      // this.lancamento.vlr_valor = "";
      this.lancamento.data_pagamento = "";
      if (window.sessionStorage.getItem("selconta") == 3) {
        this.lancamento.data_pagamento = new Date().toISOString().substr(0, 10);
      }

      this.lancamento.data_vencimento =  moment().format("YYYY-MM-DD")//new Date().toISOString().substr(0, 10);
      this.lancamento.data_lancamento = moment().format("YYYY-MM-DD")//new Date().toISOString().substr(0, 10);
      //this.lancamento.origem = "";
      this.lancamento.documento = "";
      this.lancamento.observacao = "";
      this.lancamento.id_fornecedor = "";
      this.lancamento.fornecedor = "";

      this.lancamento.id_tipo_doc = "";
      this.thistorico = "";
      this.lancamento.itensLancamento = [];
    },
    zeraItens() {
      this.itemUm.descricao = "";
      this.itemUm.vlr_valor = "";
      this.itemUm.observacao = "";
      this.itemUm.grupo_departamento = null;
      // this.itemUm.grupo_departamento.id_grupo_dep = "";
      //this.itemUm.grupo_departamento.descricao = "";

      // this.id_grupo_dep = "";
      //this.itemUm.grupo_departamento = [];
      // this.itemUm.data_vencimento = "";

      //this.itemUm.dt_vencimento = "";
      //this.itemUm.dt_lancamento = "";
    },
    openl() {
      //console.log("lancamento");
    },
    selConta(conta) {
      //this.lancamento.id_conta = e;
      //alert(tab);
      window.sessionStorage.setItem("selconta", conta);
      this.lancamento.id_conta = window.sessionStorage.getItem("selconta");

      if (conta == 3) {
        this.showDtpg = true;
        this.lancamento.data_pagamento = new Date().toISOString().substr(0, 10);
      } else {
        this.lancamento.data_pagamento = "";
        this.showDtpg = true;
      }
      if (conta == 5) {
        this.showDtpg = false;
      }
      this.buscaLancamentos();
    },
    buscaSaldos() {
      //console.log("buscanco saldos");
      this.$refs.saldos.buscaSaldos();
    },
    /*focusNext(elem) {
      const currentIndex = Array.from(elem.form.elements).indexOf(elem);
      elem.form.elements
        .item(
          currentIndex < elem.form.elements.length - 1 ? currentIndex + 1 : 0add
        )
        .focus();
    },
    addDadosComponet(e) {
      //console.log(e);
    },*/

    validacao() {
      let descricao = this.lancamento.descricao;
      if (descricao.trim() == "") {
        this.textSnack = "Preencha a descrição";
        this.mudaCampo("descricao");
        this.mostraMsg = true;
        this.corMsg = "error";
        return false;
      }
      /* if (this.lancamento.documento == "") {
        this.textSnack = "Preencha o campo Documento!";
        this.mostraMsg = true;
        this.corMsg = "error";
        this.mudaCampo("documento");
        this.$refs.documento.focus();
        return false;
      }*/
      if (window.sessionStorage.getItem("selconta") == 3) {
        if (this.lancamento.data_pagamento == "") {
          this.textSnack = "Informe a data de Pagamento";
          this.mudaCampo("data_pagamento");
          this.mostraMsg = true;
          this.corMsg = "error";
          return false;
        }
      }
      if (this.lancamento.id_tipo_doc == "") {
        this.textSnack = "Selecione um tipo de documento!";
        this.mostraMsg = true;
        this.corMsg = "error";
        this.$refs.tipo_doc.focus();
        return false;
      }
      if (!this.multiplo) {
        if (this.itemUm.grupo_departamento == null) {
          this.textSnack = "Selecione um Grupo Financeiro!";
          this.mostraMsg = true;
          this.corMsg = "error";
          this.$refs.grupo_financeiro.focus();
          return false;
        }
        if (this.itemUm.grupo_departamento.id_grupo_dep == "") {
          this.textSnack = "Selecione um Grupo Financeiro!";
          this.mostraMsg = true;
          this.corMsg = "error";
          this.$refs.grupo_financeiro.focus();
          return false;
        }
        if (this.itemUm.vlr_valor == "") {
          this.textSnack = "Informe um Valor!";
          this.mostraMsg = true;
          this.corMsg = "error";
          this.$refs.valor.focus();
          return false;
        }
        /* if (this.itemUm.observacao == "") {
          this.textSnack = "Informe uma Observação!";
          this.mostraMsg = true;
          this.corMsg = "error";
          this.$refs.observacao.focus();
          return false;
        }*/
      } else {
        if (this.lancamento.itensLancamento.length == 0) {
          this.textSnack = "Inclua pelo menos um item!";
          this.mostraMsg = true;
          this.corMsg = "error";
          this.$refs.item0.$refs.valori.focus();
          return false;
        }
      }

      return true;
    },
    alterMultiplos() {
      //console.log(this.multiplo);
      setTimeout(() => {
        this.$refs.item0.$refs.valori.focus();
      }, 100);
    },
    salvaLancamento() {
      this.salvando = true;
      if (!this.validacao()) {
        this.salvando = false;
        return false;
      }
      if (!this.multiplo) {
        this.lancamento.itensLancamento = [];
        this.lancamento.itensLancamento.push(this.itemUm);
      }
      this.multiplo = false;
      axios
        .post(
          `/fin/lancamento`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            dados: this.lancamento,
          },
          auth
        )
        .then((response) => {
          this.salvando = false;

          //this.lancpai = response.data;
          this.textSnack = "Gravado com sucesso!";
          this.mostraMsg = true;
          this.corMsg = "success";

          this.buscaSaldos();

          this.buscaLancamentos();
          this.zeraLancamentos();
          this.zeraItens();
          setTimeout(() => {
            this.mudaCampo("descricao");
          }, 3000);
        })
        .catch((e) => {
          this.loading = false;
          if (e.response.data) {
            if (!Array.isArray(e.response.data)) {
              let itens = [];
              for (var prop in e.response.data) {
                itens.push(e.response.data[prop][0]);
              }
              //console.log(e.response.data);

              this.textSnack = itens.join("");
              this.mostraMsg = true;
              this.corMsg = "error";
            } else {
              this.textSnack = e.response.data;

              this.mostraMsg = true;
              this.corMsg = "error";
            }
            this.salvando = false;
          }
        });
    },
  },
};
</script>
<style>
.v-breadcrumbs {
  padding: 5px !important;
}
.alert {
  margin-bottom: 0px !important;
}
.alert {
  padding: 0px !important;
}
</style>
