<template>
  <v-container>

    <v-row >   
   
        <v-col cols="12" xl="1" lg="2" md="2" sm="4" xs="4">
          
                <v-text-field
                  v-model="item.vlr_valor"
                  name="valor"
                  label="Valor"
                  value
                  filled
                  dense
                  prepend-inner-icon="attach_money"
                  clearable
                  v-mask-decimal.br="2"
                  hide-details
                  ref="valori"
                  :name="`valor_${Math.random()}`"
                   @keyup.enter="mudaCampo('grupo_financeiro')"
                  autocomplete="false"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xl="3" lg="2" md="3" sm="8" xs="5">
                <v-autocomplete
                  :items="grupos"
                  v-model="item.grupo_departamento"
                  name="grupo_financeiro"
                  label="Grupo Financeiro"
                  item-value="id_grupo_dep"
                  item-text="descricao"
                  return-object
                  filled
                   @keyup.enter="mudaCampo('observacao')"
                  ref="grupo_financeiro"
                  clearable
                  hide-details
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" xl="6" lg="5" md="6" sm="10" xs="12">
                <v-text-field
                  v-model="item.observacao"
                  name="observacao"
                  label="Observação"
                  value
                  filled
                  ref="observacao"
                  clearable
                  @keyup.enter="addItem()"
                  hide-details
                  autocomplete="false"
                ></v-text-field>
              </v-col>
               <v-col v-show="primeiro" cols="12" xl="1" lg="1" md="1" sm="1" xs="1">
                <v-btn dark outlined small @click="addItem()" color="success">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                  Incluir
                </v-btn>
              </v-col>
                 <v-col v-show="!primeiro" cols="12" xl="1" lg="1" md="1" sm="1" xs="3">
                <v-btn   small @click="removeItem()" color="success ">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col>
              <h6
                    style="color:#3E5286;font-size:1.35em">
                    Total: {{formatar_moeda(valorItens)}}
                    </h6>
              </v-col>
            </v-row>
              <v-snackbar
      :timeout="3000"
      :color="corMsg"
      :multi-line="'multi-line'"
      :vertical="'vertical'"
      :top="true"
      v-model="mostraMsg"
    >
      {{ textSnack }}
    </v-snackbar>
  </v-container>
</template>
<script>
var moment = require("moment");

import { formata } from "../plugins/format";

const auth = {
  headers: {
    Authorization: "bearer " + window.sessionStorage.getItem("token"),
  },
};
export default {
  name: "ItensLancamento",
  //components: { Mensagem },
  props:["primeiro","itensIn","diminua"],
  mixins: [formata],  

  mounted() {
    this.buscaGrupos();
    this.mudaCampo('valori')
   // this.$store.dispatch("addLancItem", this.item);
  },

   data: () => ({
     mostraMsg: false,
    cor: "",
    textSnack: "",
    corMsg: "",
     grupos: [],
     error:"error",
     item: {       
        grupo_departamento: { id_grupo_dep: "", descricao: "" },
        vlr_valor: "",
        observacao: "",
       
      },
         //valorItens: this.$store.state.valor_item,
  })
    
  ,
  computed: {
    valorItens:function() {return this.$store.state.valor_item}
  },

  watch: {
    itensIn:{

    },
    
    item:{
     /* deep: true,
      immediate: true,
      handler:function(e){
        this.$emit('newData', this.item)
      }
        
      */
      
    }
  },
  methods: {
    
    subValorItem(valor){
      this.valorItens = parseFloat(this.valorItens) + parseFloat(valor);
    },
    mudaCampo(campo) {
      eval("this.$refs." + campo + ".focus()");
      if (campo == "data_lancamento") {
        //this.$refs.dtpk_lancamento.showCalendar();
      }
    },
    removeItem(){
      
       //   this.$emit('removeComponente')
    },
    validItens() {
      if (!this.item.vlr_valor > 0 ) {
        this.textSnack = "Informe um valor";
        this.mudaCampo('valori')
        this.mostraMsg = true;
        this.corMsg = "error";
        return false;
      }

       if (this.item.grupo_departamento == null) {
          this.textSnack = "Selecione um Grupo Financeiro!";
          this.mostraMsg = true;
          this.corMsg = "error";
          this.$refs.grupo_financeiro.focus();
          return false;
      }
      if (this.item.grupo_departamento.id_grupo_dep == "") {
        this.textSnack = "Selecione um grupo";
        this.mudaCampo('grupo_financeiro')
        this.mostraMsg = true;
        this.corMsg = "error";
        return false;
      }    

      return true
     }, 
    addItem(){
      if (!this.validItens()) {
        return false;
      }
        

      //  this.sumValorItem(this.item.vlr_valor);
        this.$emit('addItem', this.item);
        
        this.item.grupo_departamento =null;
        this.item.observacao =''
        this.item.vlr_valor =''
    },
    buscaGrupos() {
      axios
        .post(
          `/fin/grupo/lista`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
          auth
        )
        .then((response) => {
          this.grupos = response.data;
        });
    },
   
  },
};
</script>
<style>
.v-list-item {
  min-height: 28px !important;
}
.v-list-item__title {
  font-size: 1.2em !important;
}
</style></style>
