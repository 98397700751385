<template>
  <v-main class="pa-2">
    <v-row>
      <v-col
        cols="12"
        xl="12"
        lg="12"
        md="12"
        sm="12"
        xs="12"
        class="colunaSaldo"
      >
        <saldos-inativo ref="saldos"></saldos-inativo>
      </v-col>
    </v-row>
    {{ valorSel }}
    <v-row gutters="1" class="pa-0">
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
        <v-card class="pa-5" max-width="100%" elevation="3">
          <v-row class="mt-0">
            <v-col cols="12" xl="2" lg="2" md="3" sm="3" xs="12">
              <v-radio-group @change="filtrarData()" v-model="tipo_data" row>
                <v-radio label="Lançamento" value="lancamento"></v-radio>
                <v-radio label="Vencimento" value="vencimento"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="3" sm="3" xs="6">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                transition="scroll-y-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateInicialFormatted"
                    label="Data inicial"
                    persistent-hint
                    prepend-inner-icon="event"
                    clearable
                    filled
                    hide-details=""
                    v-on="on"
                    @blur="
                      lancamento.data_inicial = parseDate(dateInicialFormatted)
                    "
                    @click:clear="
                      lancamento.data_inicial = '';
                      listaContasPagar = [];
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  format="dd/MM/yyyy"
                  v-model="lancamento.data_inicial"
                  no-title
                  title="inicial"
                  @input="
                    menu2 = false;
                    filtroBuscar();
                  "
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="3" sm="3" xs="6">
              <v-menu
                v-model="menuFin"
                :close-on-content-click="false"
                transition="scroll-y-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                hide-details
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateFinalFormatted"
                    label="Data Final"
                    persistent-hint
                    prepend-inner-icon="event"
                    filled
                    clearable
                    hide-details
                    v-on="on"
                    @blur="
                      lancamento.data_final = parseDate(dateFinalFormatted)
                    "
                    @click:clear="
                      lancamento.data_final = '';
                      listaContasPagar = [];
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  v-model="lancamento.data_final"
                  no-title
                  @input="
                    menuFin = false;
                    filtroBuscar();
                  "
                  hide-details
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" xl="2" lg="2" md="3" sm="2" xs="2">
              <v-menu
                ref="menu3"
                v-model="menu3"
                :close-on-content-click="false"
                transition="scroll-y-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                hide-details
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="datePagamentoFormatted"
                    label="Data Pagamento"
                    persistent-hint
                    prepend-inner-icon="event"
                    @blur="
                      lancamento.data_pagamento = parseDate(
                        datePagamentoFormatted
                      )
                    "
                    v-on="on"
                    filled
                    clearable
                    hide-details
                    @click:clear="lancamento.data_pagamento = ''"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  v-model="lancamento.data_pagamento"
                  no-title
                  @input="menu3 = false"
                  hide-details
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" xl="2" lg="2" md="6" sm="6" xs="6">
              <v-autocomplete
                transition="fab-transition"
                :items="listaContas"
                v-model="lancamento.id_conta"
                name="conta"
                label="Pagar Com"
                item-value="id_conta"
                item-text="conta"
                value
                filled
                @change="atrribuirConta(lancamento.id_conta)"
                clearable
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-divider vertical></v-divider>

            <v-col cols="12" xl="1" lg="1" md="2" sm="6" xs="2">
              <v-btn
                small
                block
                dark
                @click="pagarLancamento(listaContasPagar)"
                color="bt_pagar"
              >
                Pagar
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
              <v-data-table
                :headers="headers"
                :items="listaContasPagar"
                :loading="loading_table"
                loading-text="Carregando... Aguarde"
                :search="search"
                dense
                no-data-text="Nenhum resultado encontrado!"
                no-results-text="Nenhum resultado encontrado!"
                class="elevation-1"
              >
                <template v-slot:item.vlr_total="{ item }">
                  <span>{{ formatar_valor(item.vlr_total) }}</span>
                </template>
                <template v-slot:item.data_lancamento="{ item }">
                  <span>{{ formatDate(item.data_lancamento) }}</span>
                </template>
                <template v-slot:item.data_vencimento="{ item }">
                  <span>{{ formatDate(item.data_vencimento) }}</span>
                </template>
                <template v-slot:item.pai="{ item }">
                  <div v-if="item.pai">
                    <v-btn
                      icon
                      color="error"
                      @click="openFilhos(item.pai)"
                      fab
                      x-small
                    >
                      <v-icon>mdi-link-variant</v-icon>
                    </v-btn>
                  </div>
                </template>
                <template v-slot:item.id_conta="{ item, index }">
                  <v-autocomplete
                    :items="listaContas"
                    name="conta"
                    item-value="id_conta"
                    item-text="conta"
                    :value="valorSel"
                    filled
                    clearable
                    :name="`id_conta_${Math.random()}`"
                    ref="id_conta"
                    @change="addContaItem(item.id_lancamento, index, $event)"
                    hide-details
                  ></v-autocomplete>
                </template>

                <!--<template v-slot:item.acao="{ item }">
                  <span>
                    <v-btn
                      class="mx-0"
                      @click="showPagar(item)"
                      icon
                      color="primary"
                    >
                      <v-icon>
                        mdi-currency-usd-circle
                      </v-icon>
                    </v-btn></span
                  >
                </template>-->
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogPagar" max-width="600px">
      <v-card>
        <v-card-title class=""
          >Confirma o Pagamento Deste Lançamento?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closePagar">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="pagarUnico()">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogItens" ref="janelaItens" max-width="1185px">
      <DialogItensLancamento
        ref="dialogIt"
        :id_pai="id_pai"
        @fechouDialog="dialogItens = false"
      ></DialogItensLancamento>
    </v-dialog>
  </v-main>
</template>
<script>
var moment = require("moment");
import SaldosInativo from "./SaldoInativo.vue";
import { formata } from "../plugins/format";
import DialogItensLancamento from "./DialogItensLancamento.vue";
const auth = {
  headers: {
    Authorization: "bearer " + window.sessionStorage.getItem("token"),
  },
};
export default {
  name: "TabContasPagar",
  mixins: [formata],
  components: { SaldosInativo, DialogItensLancamento },
  props: {
    source: String,
  },
  mounted() {
    // this.buscaGrupos();
    this.buscaSaldos();
    this.buscaContas();

    // setTimeout(() => this.buscaContasPagar(), 500);

    //  this.buscaTipoDoc();
  },
  data: (vm) => ({
    valorSel: null,
    selFormaPagto: "",
    tipo_data: "vencimento",
    dialogItens: false,
    id_pai: "",
    lancamento: {
      descricao: "",
      id_fin_grupo_departamento: "",
      vlr_valor: "",
      data_pagamento: moment().format("YYYY-MM-DD"),
      data_inicial: moment().format("YYYY-MM-01"),
      data_final: moment().format("YYYY-MM-DD"),

      id_conta: "",
    },
    selDel: "",
    dialogDelete: false,
    barSaldos: true,
    barTabela: false,
    isLoading: false,

    datePagamentoFormatted: moment().format("DD/MM/YYYY"),

    dateInicialFormatted: moment().format("01/MM/YYYY"),
    dateFinalFormatted: moment().format("DD/MM/YYYY"),
    menu1: false,
    menu2: false,
    menu3: false,
    menuFin: false,
    loading_table: false,
    headers: [],
    search: "",
    loading_filtrar: false,
    loading_baixar: false,
    //grupos: [],

    historicos: [],
    fornecedores: [],
    listaTipoDoc: [],

    saldos: [],
    listaContasPagar: [],
    dialogPagar: false,
    selPagar: "",

    lancSelPagar: [],
    headers: [
      {
        text: "Nº",
        sortable: true,
        align: "left",
        value: "id_lancamento",
      },
      {
        text: "Descrição",
        value: "descricao",
      },
      {
        text: "Identif.",
        value: "identif",
        align: "left",
        width: "10%",
      },
      {
        text: "Data",
        value: "data_lancamento",
        align: "right",
      },
      {
        text: "Valor",
        value: "vlr_total",
        align: "right",
      },

      {
        text: "Vencimento",
        value: "data_vencimento",
        align: "right",
      },
      {
        text: "Tipo",
        value: "tipo",
        align: "left",
      },
      {
        text: "Documento",
        value: "documento",
        align: "left",
      },
      {
        text: "Forma Pagto",
        value: "id_conta",
        align: "left",
        width: "20%",
      },
    ],
    listaContas: [],
    v_autoselect: null,
    conta: 2,
  }),
  computed: {
    dateInFormatted() {
      return this.formatDate(this.lancamento.data_lancamento);
    },
    /*dateVencimentoFormatted() {
      return this.formatDate(this.lancamento.data_vencimento);
    },*/
  },

  watch: {
    v_autoselect(val) {
      console.log("auto" + val);
    },
    "lancamento.data_inicial"(val) {
      this.dateInicialFormatted = this.formatDate(this.lancamento.data_inicial);
    },
    "lancamento.data_final"(val) {
      this.dateFinalFormatted = this.formatDate(this.lancamento.data_final);
    },
    "lancamento.data_pagamento"(val) {
      this.datePagamentoFormatted = this.formatDate(
        this.lancamento.data_pagamento
      );
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogItens(val) {
      if (val == true) {
        this.buscaDadosItens();
      }
    },
  },
  methods: {
    zeraCampos() {
      //   alert("campos");
      this.lancamento.id_conta = "";
      this.valorSel = "";
    },
    mudaTab(tab) {
      //alert("conta" + conta);
      //this.conta;
      //this.lancamento.id_conta = e;
      //alert(e);
      //  this.$refs.lancamento.selConta(e);
      this.$emit("mudaTab", tab);
    },
    atrribuirConta(val) {
      this.valorSel = val;
      for (let [key, value] of Object.entries(this.listaContasPagar)) {
        //console.log(`${key}: ${value}`);
        this.listaContasPagar[key].id_conta = val;
        //this.listaContasPagar[key].data_pagamento = val;
      }
    },
    addContaItem(id_lancamento, index, e) {
      // this.lancSelPagar.push({ id_lanc: id_lancamento, conta: e });
      //alert(id_lancamento);
      //  alert(e.target.value);
      // console.log("indkkk" + index);
      // console.log(this.lancSelPagar);
      this.listaContasPagar[index].id_conta = e;
    },
    buscaDadosItens() {
      setTimeout(
        () => this.$refs.dialogIt.buscaLancamentosFilhos(this.id_pai),
        300
      );
    },
    openFilhos(id_pai) {
      this.id_pai = id_pai;
      setTimeout(() => (this.dialogItens = true), 200);
    },
    closePagar() {
      this.dialogPagar = false;
    },
    showPagar(item) {
      if (
        this.lancamento.data_pagamento == "" ||
        this.lancamento.data_pagamento == null
      ) {
        alert("informe a data de pagamento");
        return false;
      }
      this.selPagar = item;
      console.log(this.selPagar);
      this.dialogPagar = true;
    },
    pagarUnico() {
      axios
        .post(
          `/fin/lancamento/pagarUm`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            id_lancamento: this.selPagar.id_lancamento,
            vlr_pago: this.selPagar.vlr_inicial,
            pai: this.selPagar.pai,
            data_pagamento: this.datePagamentoFormatted,
            id_conta: this.lancamento.id_conta,
          },
          auth
        )
        .then((response) => {
          console.log(response);
          this.dialogPagar = false;
          this.buscaSaldos();
          // this.buscaSaldos();
          this.buscaContasPagar();
          //this.lancpai = response.data;
        });
    },
    pagarLancamento(itens) {
      if (
        this.lancamento.data_pagamento == "" ||
        this.lancamento.data_pagamento == null
      ) {
        alert("informe a data de pagamento");
        return false;
      }
      let lancs = [];
      console.log(itens);
      //return false;
      for (var it in itens) {
        if (itens[it].id_conta != null && itens[it].id_conta != 5) {
          var dataObj = {
            id_lancamento: itens[it].id_lancamento_item,
            id_lanc: itens[it].id_lancamento,
            vlr_pago: itens[it].vlr_total,
            id_conta: itens[it].id_conta,
          };
          lancs.push(dataObj);
        }
      }
      //console.log(lancs);
      //console.log(this.parseDate(this.datePagamentoFormatted));
      //return false;
      axios
        .post(
          `/fin/lancamento/pagar`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            dados: lancs,
            data_pagamento: this.parseDate(this.datePagamentoFormatted),
          },
          auth
        )
        .then((response) => {
          console.log(response);
          // this.buscaSaldos();
          this.buscaSaldos();
          this.buscaContasPagar();
          this.valorSel = "";
          //this.lancpai = response.data;
        });
    },
    deleteItem(item) {
      console.log(item);
      console.log(item.id_lancamento);
      //this.editedIndex = this.lancamento.indexOf(item);
      //this.editedItem = Object.assign({}, item);
      this.selDel = item.id_lancamento;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.listaLancamentos.splice(this.editedIndex, 1);
      this.closeDelete();
      axios
        .post(
          `/fin/lancamento/remove`,
          { id: this.selDel, cnpj: window.sessionStorage.getItem("cnpj") },
          auth
        )
        .then((response) => {
          this.selDel = "";
          this.buscaContasPagar();
          console.log(response);
          //this.grupos = response.data;
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    editItem(item) {
      this.lancamento = item;
    },
    formata_moeda(val) {
      let valor = this.formatar_moeda(val);
      return valor;
    },
    addDescricao(val) {
      this.lancamento.descricao = val;
    },
    selecionarConta(id) {
      this.lancamento.id_conta = id;
      //  sessionStorage.setItem("selconta", id);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getStatus(status) {
      if (status === "Cancelada") {
        return "cancelada";
      }
    },

    selConta(e) {
      console.log(e);
      //this.lancamento.id_conta = e;
      // alert(e);
      //this.$refs.lancamento.selConta(e);
    },
    buscaSaldos() {
      this.$refs.saldos.buscaSaldos();
    },
    filtroBuscar() {
      setTimeout(() => this.buscaContasPagar(), 500);
    },
    buscaContasPagar() {
      /*if (
        this.lancamento.id_fin_grupo_departamento == "" ||
        this.lancamento.id_fin_grupo_departamento == null
      ) {
        return false;
      }*/
      this.loading_table = true;
      axios
        .post(
          `/fin/lancamento/contas_pagar`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            data_inicial: this.lancamento.data_inicial,
            data_final: this.lancamento.data_final,
            tipo_data: this.tipo_data,
          },
          auth
        )
        .then((response) => {
          this.listaContasPagar = response.data;
          this.loading_table = false;
        });
    },
    filtrarData() {
      this.filtroBuscar();
    },
    buscaContas(val) {
      this.listaContas = [];
      axios
        .post(
          `/fin/conta/lista`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
          auth
        )
        .then((response) => {
          this.listaContas = response.data;
        });
    },
  },
  /*removeConta() {
    alert("remove");
  },*/
};
</script>
<style scoped>
.ativa {
  border: 2px solid #000 !important;
}
</style>
