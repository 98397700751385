<template>
  <div>

    <v-chip
         v-if="chip1"
              class="ma-2"
              close
              color="success"
              outlined
              @click:close="chip1 = false"
            >
              {{item.grupo_departamento.descricao}} - {{item.vlr_valor}}
            </v-chip>
  </div>
</template>
<script>
/*var moment = require("moment");*/

import { formata } from "../plugins/format";

const auth = {
  headers: {
    Authorization: "bearer " + window.sessionStorage.getItem("token"),
  },
};
export default {
  name: "Chips",
  //components: { Mensagem },
  props:["item"],
  mixins: [formata],  

  mounted() {
   // this.buscaGrupos();
   // this.$store.dispatch("addLancItem", this.item);
  },

   data: (vm) => ({
     //grupos: [],
     chip1:true,
     error:"error",
    
  })
    
  ,
  computed: {},

  watch: {
   
  },
  methods: {
      removeItem(){
       
          this.$emit('removeComponente')
      },
      addItem(){
        //this.$emit('addItem', this.item);
        //this.item.grupo_departamento =[];
        //this.item.observacao =''
        //this.item.vlr_valor =''
      },
      buscaGrupos() {
      axios
        .post(
          `/fin/grupo/lista`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
          auth
        )
        .then((response) => {
          this.grupos = response.data;
        });
    },
   
  },
};
</script>
<style>
.v-list-item {
  min-height: 28px !important;
}
.v-list-item__title {
  font-size: 1.2em !important;
}
</style></style>
